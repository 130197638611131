import axios from "axios";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function GeneralSalesDetails({ taxAccounts, customerDetails, refreshFunc }) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();

  const [locationData, setLocationData] = useState(null);
  const [salesPerson, setSalesPerson] = useState(null);
  const [salesManagers, setSalesManagers] = useState(null);

  const [showTaxModal, setShowTaxModal] = useState(false);
  const [w9File, setW9File] = useState(null);
  const [examptionFile, setExamptionFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({ ...customerDetails });

  useEffect(() => {
    setFormData(customerDetails);
  }, [customerDetails]);

  /*   All Functions
   ********************************************* */
  const updateTaxDetails = async (e) => {
    e.preventDefault();
    let updateData = {
      ...formData,
      locationId: formData.locationId === "null" ? null : formData.locationId,
      salesPersonId:
        formData.salesPersonId === "null" ? null : formData.salesPersonId,
      salesManager:
        formData.salesManager === "null" ? null : formData.salesManager,
      taxAccountId:
        formData.taxAccountId === "null" ? null : formData.taxAccountId,
    };
    const updatedData = JSON.stringify({
      ...updateData,
      id: customerDetails.id,
    });

    setIsLoading(true);
    try {
      const { data } = await API.patch(
        "/customers",
        { updatedData },
        { headers: authHeader() }
      );

      if (data.success) {
        if (w9File) await uploadFiles(w9File, "w9File");
        if (examptionFile) await uploadFiles(examptionFile, "examptionFile");
        refreshFunc(customerDetails.id);
        setIsLoading(false);
        setShowTaxModal(false);
        return;
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  const getLocations = async () => {
    try {
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocationData(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSalesPerson = async () => {
    try {
      const { data } = await API.get("/salespersons", {
        headers: authHeader(),
      });
      setSalesPerson(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSalesManagers = async () => {
    try {
      const { data } = await API.get("/salesmanagers", {
        headers: authHeader(),
      });
      setSalesManagers(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLocationName = (id) => {
    const item = locationData?.find((item) => item.id === id);
    if (item) {
      return item.locationName;
    } else {
      return null;
    }
  };

  const getSalesPersonName = (id) => {
    const item = salesPerson?.find((item) => item.id === id);
    if (item) {
      return item.name;
    } else {
      return null;
    }
  };

  const getSalesManagerName = (id) => {
    const item = salesManagers?.find((item) => item.id === id);
    if (item) {
      return `${item.firstName} ${item.lastName}`;
    } else {
      return null;
    }
  };

  const getTaxAccountsName = (id) => {
    const item = taxAccounts?.find((item) => item.id === id);
    if (item) {
      return `${item?.taxAccountNumber} : ${item?.taxAccountName}`;
    } else {
      return null;
    }
  };

  /*   Files
   ********************************************* */
  const handleW9File = (event) => {
    setW9File(event.target.files[0]);
  };

  const handleExamptionFile = (event) => {
    setExamptionFile(event.target.files[0]);
  };

  const uploadFiles = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "folder",
      `kms/customer/${customerDetails.id}/certificates`
    );
    formData.append("documentType", type);
    formData.append("id", customerDetails?.id);

    if (file == null) {
      dispatch(
        setNotification({
          message: "No file selected for upload.",
          type: "error",
        })
      );
      return;
    }

    await API.post("/customers/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const downloadFile = (fileKey) => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: fileKey,
      })
      .then((response) => {
        const url = response.data[0];
        const link = document.createElement("a");
        link.href = url;
        link.download = fileKey;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  const deleteFile = async (id, type) => {
    setIsLoading(true);
    try {
      const { data } = await API.post(
        "/customers-file/delete-file",
        {
          file: id,
          documentType: type,
          id: customerDetails?.id,
        },
        { headers: authHeader() }
      );
      dispatch(
        setNotification({
          message: data,
          type: "success",
        })
      );
      refreshFunc(customerDetails?.id);
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (customerDetails) {
      setFormData({ ...customerDetails });
    }
  }, [customerDetails]);

  useEffect(() => {
    getLocations();
    getSalesPerson();
    getSalesManagers();
  }, []);

  if (!locationData || !salesManagers || !salesPerson) return <Spinner />;

  return (
    <div className="flex-grow-1">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-grow-1">
          <h4 className="border-bottom border-secondary">
            GENERAL SALES DETAIL
          </h4>
        </div>
        <button
          className="btn d-flex justify-content-center align-items-center btn-sm btn-success"
          style={{ width: 28, height: 28 }}
          onClick={() => {
            setShowTaxModal(true);
          }}
        >
          <i className="mdi fs-5 mdi-pencil"></i>
        </button>
      </div>
      <div className="row">
        <div className="col-5">Location</div>
        <div className="col-1">:</div>
        <div className="col-6">
          {getLocationName(customerDetails?.locationId)}
        </div>
      </div>

      <div className="row">
        <div className="col-5">Inside Sales Person</div>
        <div className="col-1">:</div>
        <div className="col-6">
          {getSalesManagerName(customerDetails?.salesManager)}
        </div>
      </div>
      <div className="row">
        <div className="col-5">Outside Sales Person</div>
        <div className="col-1">:</div>
        <div className="col-6">
          {getSalesPersonName(customerDetails?.salesPersonId)}
        </div>
      </div>

      <hr className="me-2" />
      <div className="d-flex">
        <h4 className="border-bottom border-secondary">TAX DETAILS</h4>
      </div>
      <div className="row">
        <div className="col-5">
          <b>Tax Account</b>
        </div>
        <div className="col-1"> :</div>
        <div className="col-6">
          {customerDetails?.taxAccountId
            ? getTaxAccountsName(customerDetails?.taxAccountId)
            : "-"}
        </div>
      </div>
      <div className="row">
        <div className="col-5">Tax Exampted</div>
        <div className="col-1"> :</div>
        <div className="col-6">
          {customerDetails?.isTaxExamption ? "Yes" : "No"}
        </div>
      </div>
      <div className="row">
        <div className="col-5">Tax Number</div>
        <div className="col-1"> :</div>
        <div className="col-6">
          {customerDetails?.taxNumber ? customerDetails?.taxNumber : "-"}
        </div>
      </div>
      <div className="row">
        <div className="col-5">W-9</div>
        <div className="col-1"> :</div>
        <div className="col-6 d-flex justify-content-between items-center ">
          <div>{customerDetails?.w9File ? "Added" : "Not Added"}</div>
        </div>
      </div>
      <div className="row d-flex align-items-center">
        <div className="col-5 pe-0">Tax Examption Certificate</div>
        <div className="col-1"> :</div>
        <div className="col-6 d-flex justify-content-between items-center my-0 py-0">
          <div>{customerDetails?.examptionFile ? "Added" : "Not Added"}</div>
        </div>
      </div>

      {/*   Tax Details
       ********************************************* */}
      <Modal show={showTaxModal} onHide={() => setShowTaxModal(false)}>
        <Modal.Body>
          <div className="">
            <form onSubmit={updateTaxDetails}>
              {/*   Tax Person Edit
               ********************************************* */}
              <div className="">
                <h4 className="mb-0">General Sales Details</h4>
              </div>
              <hr className="mt-0" />

              <div className="row mb-1">
                <label className="col-3">Location</label>
                <div className="col-9 ">
                  <select
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        locationId: parseInt(e.target.value),
                      })
                    }
                    value={formData?.locationId || ""}
                    className="form-select "
                  >
                    <option value="null">None</option>
                    {locationData?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.locationName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3">Inside Sales Person</label>
                <div className="col-9 ">
                  <select
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        salesManager: parseInt(e.target.value),
                      })
                    }
                    value={formData?.salesManager || "null"}
                    className="form-select "
                  >
                    <option value="null">None</option>
                    {salesManagers?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.firstName} {item?.lastName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3">Outside Sales Person</label>
                <div className="col-9 ">
                  <select
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        salesPersonId: parseInt(e.target.value),
                      })
                    }
                    value={formData?.salesPersonId || ""}
                    className="form-select"
                  >
                    <option value="null">None</option>
                    {salesPerson?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/*   Tax details
               ********************************************* */}
              <div className="">
                <h4 className="mb-0">Tax Details</h4>
              </div>
              <hr className="mt-0" />
              <div className="row mb-1">
                <label className="col-3">Tax Account</label>
                <div className="col-9 ">
                  <select
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        taxAccountId: parseInt(e.target.value),
                      })
                    }
                    className="form-select"
                  >
                    <option value="null">None</option>
                    {taxAccounts?.map((item) => (
                      <option
                        key={item?.id}
                        selected={customerDetails?.taxAccountId === item?.id}
                        value={item?.id}
                      >
                        {item?.taxAccountName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3">Tax Exampted</label>
                <div className="col-9 ">
                  <input
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        isTaxExamption: e.target.checked,
                      })
                    }
                    defaultChecked={formData?.isTaxExamption}
                    type="checkbox"
                    id="switch0"
                    data-switch="none"
                  />
                  <label
                    htmlFor="switch0"
                    data-on-label="Yes"
                    data-off-label="No"
                  ></label>
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3">Tax Number</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.taxNumber}
                    onChange={(e) =>
                      setFormData({ ...formData, taxNumber: e.target.value })
                    }
                    className="form-control"
                    placeholder="Enter Tax ID"
                  />
                </div>
              </div>

              <div className="row mb-1">
                <label className="col-3">W-9 Certificate</label>
                <div className="col-9 d-flex">
                  <div className="flex-grow-1">
                    {formData?.w9File ? (
                      <div className="form-control">
                        {
                          formData?.w9File?.split("/")[
                            formData?.w9File?.split("/").length - 1
                          ]
                        }
                      </div>
                    ) : (
                      <input
                        disabled={formData?.w9File}
                        type="file"
                        onChange={handleW9File}
                        className="form-control"
                      />
                    )}
                  </div>
                  <div className="">
                    <button
                      disabled={!formData?.w9File}
                      onClick={() => downloadFile(formData?.w9File)}
                      type="button"
                      className={`btn btn-sm btn-${
                        formData?.w9File ? "success" : "secondary"
                      } rounded py-0 px-1 ms-1`}
                    >
                      <i className="mdi mdi-arrow-down-bold fs-3"> </i>
                    </button>
                  </div>
                  <div>
                    <button
                      disabled={!formData?.w9File}
                      onClick={() => {
                        deleteFile(formData?.w9File, "w9File");
                        setW9File(null);
                      }}
                      type="button"
                      className={`btn btn-sm btn-${
                        formData?.w9File ? "danger" : "secondary"
                      } rounded py-0 px-1 ms-1`}
                    >
                      <i className="mdi mdi-trash-can-outline fs-3"> </i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3">Tax Examption Certificate</label>
                <div className="col-9 d-flex">
                  <div className="flex-grow-1">
                    {formData?.examptionFile ? (
                      <div className="form-control">
                        {
                          formData?.examptionFile?.split("/")[
                            formData?.examptionFile?.split("/").length - 1
                          ]
                        }
                      </div>
                    ) : (
                      <input
                        disabled={formData?.examptionFile}
                        type="file"
                        onChange={handleExamptionFile}
                        className="form-control"
                      />
                    )}
                  </div>
                  <div className="">
                    <button
                      disabled={!formData?.examptionFile}
                      onClick={() => downloadFile(formData?.examptionFile)}
                      type="button"
                      className={`btn btn-sm btn-${
                        formData?.examptionFile ? "success" : "secondary"
                      } rounded py-0 px-1 ms-1`}
                    >
                      <i className="mdi mdi-arrow-down-bold fs-3"> </i>
                    </button>
                  </div>
                  <div>
                    <button
                      disabled={!formData?.examptionFile}
                      onClick={() => {
                        deleteFile(formData?.examptionFile, "examptionFile");
                        setExamptionFile(null);
                      }}
                      type="button"
                      className={`btn btn-sm btn-${
                        formData?.examptionFile ? "danger" : "secondary"
                      } rounded py-0 px-1 ms-1`}
                    >
                      <i className="mdi mdi-trash-can-outline fs-3"> </i>
                    </button>
                  </div>
                </div>
              </div>
              {isLoading && <Spinner />}

              {/* Buttons */}
              <div className="flex-grow-1 mt-3 d-flex justify-content-end align-items-end">
                <div>
                  <button
                    onClick={() => setShowTaxModal(false)}
                    type="button"
                    className="btn btn-secondary me-2 px-1"
                  >
                    CLOSE
                  </button>

                  <button type="submit" className="btn btn-primary">
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GeneralSalesDetails;

import Table from "components/DataTable/Table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import MockupFabricsAdd from "./MockupFabricsAdd.jsx";
import MockupFabricsUpdate from "./MockupFabricsUpdate.jsx";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";
import Toast from "components/Toast/ToastComponent";
import Spinner from "components/Spinner";
import DeleteModalButton from "pages/artApproval/Mockups/components/DeleteButton/DeleteModalButton";

function MockupFabrics() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  /*   All States Below
   ********************************************* */
  const [mockupFabrics, setMockupFabrics] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // below update data is used to set a variable before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions Below
   ********************************************* */

  // get All mockupFabrics
  const getAllMockupFabrics = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-fabrics", {
        headers: authHeader(),
      });

      if (data?.type === "success") {
        let resultData = data.data;
        setMockupFabrics(resultData);
        setLastSortId(
          resultData[resultData.length - 1]?.sortId
            ? resultData[resultData.length - 1]?.sortId
            : 0
        );
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Delete password Data
  const deleteMockupFabricsData = async ({ id }) => {
    try {
      loadingOn();
      const { data } = await API.delete("/mockup-fabric/" + id, {
        headers: authHeader(),
      });
      if (data.type === "success") {
        getAllMockupFabrics();
        return;
      }
      console.log(data);
      loadingOff();
    } catch (err) {
      console.log(err.message);
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = mockupFabrics[index].sortId;
    const currentId = mockupFabrics[index].id;
    const upSortId = mockupFabrics[index - 1].sortId;
    const upId = mockupFabrics[index - 1].id;
    try {
      loadingOn();
      const { data } = await API.post(
        "/mockup-fabric/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        await getAllMockupFabrics();
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = mockupFabrics[index].sortId;
    const currentId = mockupFabrics[index].id;
    const downSortId = mockupFabrics[index + 1].sortId;
    const downId = mockupFabrics[index + 1].id;
    try {
      loadingOn();
      const { data } = await API.post(
        "/mockup-fabric/sortDown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        await getAllMockupFabrics();
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  useEffect(() => {
    getAllMockupFabrics();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className="d-flex justify-content-end ms-2">
      <button onClick={() => setShowAddModal(true)} className="btn btn-primary">
        ADD NEW
      </button>
    </div>
  );
  const table_head = ["Name", "Action"];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td className="table-action">
        {user?.roles === "admin" && (
          <div
            onClick={() => {
              item.sortId !== 1 && sortUp(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-up"></i>
          </div>
        )}
        {user?.roles === "admin" && (
          <div
            onClick={() => {
              item.sortId !== mockupFabrics.length && sortDown(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-down"></i>
          </div>
        )}
        <div className="action-icon">
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className="mdi mdi-pencil"
          ></i>
        </div>
        <DeleteModalButton
          deleteFunc={deleteMockupFabricsData}
          deleteParams={{ id: item?.id }}
          disableConditions={loading}
          btnClass={"btn btn-sm btn-danger"}
        />
      </td>
    </tr>
  );

  return (
    <div className="row">
      <h3>Mockup Fabrics</h3>
      {loading && <Spinner />}
      />
      <div className="col-12">
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={mockupFabrics}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body className="custom-shadow">
            <div>
              <MockupFabricsAdd
                showAddPage={setShowAddModal}
                refreshFunc={getAllMockupFabrics}
                lastSortId={lastSortId}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body className="custom-shadow">
            <div>
              {updateData && (
                <MockupFabricsUpdate
                  updateData={updateData}
                  showUpdatePage={setShowUpdateModal}
                  refreshFunc={getAllMockupFabrics}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default MockupFabrics;

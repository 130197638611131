import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function FabricSelectModal({
  showModal,
  selectedFabric,
  setSelectedFabric,
  fabricsList,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [mockupFabrics, setMockupFabrics] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getAllMockupFabrics = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-fabrics", {
        headers: authHeader(),
      });

      if (data?.type === "success") {
        let resultData = data.data;
        setMockupFabrics(resultData);
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getFabricNameById = (id) => {
    let itemWithMatchedId = mockupFabrics?.find(
      (item) => item.id === parseInt(id)
    );

    return itemWithMatchedId?.name;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getAllMockupFabrics();
  }, [fabricsList]);
  return (
    <div>
      <div className="">
        {loading && <Spinner />}
        />
        <h3>Select Fabrics</h3>
        <div className="my-3">
          {mockupFabrics &&
            !fabricsList &&
            mockupFabrics?.map((fabric) => (
              <div key={fabric.id} className="d-flex gap-2 align-items-center">
                <input
                  type="radio"
                  name="fabric"
                  value={fabric.id}
                  defaultChecked={parseInt(selectedFabric) === fabric?.id}
                  onChange={() => setSelectedFabric(fabric?.id)}
                />
                <div className="">{fabric?.name}</div>
              </div>
            ))}
          {fabricsList &&
            fabricsList?.map((item) => (
              <div key={item} className="d-flex gap-2 align-items-center">
                <input
                  type="radio"
                  name="fabric"
                  value={item}
                  onChange={() => setSelectedFabric(item)}
                />
                <div className="">{getFabricNameById(item)}</div>
              </div>
            ))}
        </div>
        <div className="mt-3 text-end">
          <button
            onClick={() => {
              showModal(false);
            }}
            type="button"
            className="btn btn-sm btn-success"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

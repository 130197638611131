import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import WOProductQuickView from "./WOProductQuickView";

export default function JobBoardFullViewData({ filterBy, WOData }) {
  /*   All States
   ********************************************* */
  const [searchText, setSearchText] = useState("");
  const [fullViewData, setFullViewData] = useState(null);

  const [showProductsModal, setShowProductsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  /*   All Functions
   ********************************************* */
  const getTotalQty = (products) => {
    if (products && products.length > 0) {
      let totalQty = 0;
      products?.map((item) => {
        totalQty += item?.totalQuantity;
      });
      return totalQty;
    } else {
      return 0;
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (WOData) {
      setFullViewData(WOData);
    }
  }, [WOData]);

  useEffect(() => {
    if (searchText) {
      let filteredItems = WOData?.filter((item) => {
        return (
          item?.id.toString().includes(searchText) ||
          item?.jobName.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.customerName.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFullViewData(filteredItems);
    } else {
      setFullViewData(WOData);
    }
  }, [searchText]);

  return (
    <div className="" style={{ height: "78vh" }}>
      {/*   View ALL Modal
       ********************************************* */}
      <div className="topHeader bg-primary text-white custom-border-radius d-flex justify-content-between align-items-center">
        <div>{filterBy}</div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <input
              type="search"
              placeholder="Search by job name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="form-control me-3"
            />
          </div>

          <div>{fullViewData?.length}</div>
        </div>
      </div>
      <div className="bodyHeader row">
        <div className="col-1">ID</div>
        <div className="col-5">Job Name</div>
        <div className="col-4">Customer Name</div>
        <div className="col-2">Quantity</div>
      </div>
      <div className="bodyContent-2 overflow-auto ">
        {fullViewData?.map((item, index) => (
          <div
            onDoubleClick={() => {
              setShowProductsModal(true);
              setSelectedItem(item);
            }}
            key={index}
            className="row item"
          >
            <div className="col-1">{item?.woId}</div>
            <div className=" col-5 text-nowrap">
              {item?.jobName?.slice(0, 30)}
              {item?.jobName?.length >= 30 ? "..." : ""}
            </div>
            <div className=" col-4">
              {item?.customerName?.slice(0, 30)}
              {item?.customerName?.length >= 30 ? "..." : ""}
            </div>
            <div className="col-2 text-end">
              {getTotalQty(item?.WOProducts)}
            </div>
          </div>
        ))}
      </div>

      {/*   Quick View Modal
       ********************************************* */}
      <Modal
        size="xl"
        show={showProductsModal}
        onHide={() => setShowProductsModal(false)}
      >
        <Modal.Body>
          {selectedItem && (
            <WOProductQuickView
              filterBy={filterBy}
              selectedWOID={selectedItem?.id}
              allWOData={fullViewData}
              setShowProductsModal={setShowProductsModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

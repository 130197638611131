import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

import logo from "../assets/images/logo.png";
import Avatar from "../assets/images/users/avatar-1.jpg";

import { resetCompany } from "features/company/companySlice";
import { resetSettings } from "features/settings/settingsSlice";
import { logout, updateUser } from "features/user/userSlice";
import { socket } from "../socket";
import ImgWithSignedUrl from "./SignedUrl/ImgWithSignedUrl";
import useCompanyLogo from "features/Contexts/CompanyLogoContext";
import Toast from "./Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";

function NavBar() {
  const user = useSelector((state) => state.user?.userInfo);
  const company = useSelector((state) => state.company?.company);
  const companyLogo = useSelector(
    (state) => state?.company?.company?.companyLogo
  );
  const { tempLogo } = useCompanyLogo();

  const dispatch = useDispatch();

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [smsUnreadCount, setSmsUnreadCount] = useState(0);
  const [mockupsUnreadCount, setMockupsUnreadCount] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customersList, setCustomersList] = useState(null);

  const [newSmsMessage, setNewSmsMessage] = useState(false);
  const [newMockupMessage, setNewMockupMessage] = useState(false);

  const getSmsUnreadCount = async () => {
    const {
      data: { count },
    } = await API.get("/sms/count", { headers: authHeader() });
    if (count > 0) {
      setSmsUnreadCount(count);
      setNewSmsMessage(true);
    } else {
      setSmsUnreadCount(0);
      setNewSmsMessage(false);
    }
    return count;
  };

  const getMockupsUnreadCount = async () => {
    try {
      const { data } = await API.get("/design-request/unread-count", {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        if (data?.count > 0) {
          setMockupsUnreadCount(data?.count);
          setNewMockupMessage(true);
        } else {
          setMockupsUnreadCount(data?.count);
          setNewMockupMessage(false);
        }
      }
      return data?.count;
    } catch (err) {
      console.log(err.message);
      console.log(err);

      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const userLogout = () => {
    dispatch(resetSettings());
    dispatch(resetCompany());
    dispatch(logout());
  };

  const getCustomersList = async () => {
    try {
      if (!user.email) {
        return;
      }

      const { data } = await API.post(
        "/portal-customers-company",
        { email: user.email, companyId: company?.id },
        {
          headers: authHeader(),
        }
      );

      setCustomersList(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const changeCustomer = async (customerId) => {
    let updatedUserInfo = JSON.parse(JSON.stringify(user));

    let customerInfo = customersList.find(
      (item) => item?.id === parseInt(customerId)
    );

    updatedUserInfo.cid = customerId;
    updatedUserInfo.firstName = customerInfo?.customerName.split(" ")[0];
    updatedUserInfo.lastName = customerInfo?.customerName.split(" ")[1];

    localStorage.setItem("user", JSON.stringify(updatedUserInfo));
    await dispatch(updateUser(updatedUserInfo));

    window.location.reload();
  };

  useEffect(() => {
    if (selectedCustomer) {
      changeCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    // getCustomersList();
    getMockupsUnreadCount();
  }, []);

  useEffect(() => {
    if (
      user?.permissions?.includes("sms_view") ||
      user?.permissions?.includes("sms_send") ||
      user?.roles === "admin"
    ) {
      // getSmsUnreadCount();
    }
  }, []);

  useEffect(() => {
    async function getUnreadSmsCount() {
      if (
        user?.permissions?.includes("sms_view") ||
        user?.permissions?.includes("sms_send") ||
        user?.roles === "admin"
      ) {
        const count = await getSmsUnreadCount();
        return count;
      }
    }
    async function getUnreadMockupCount() {
      const count = await getMockupsUnreadCount();
      return count;
    }

    function onConnect() {
      setIsConnected(true);
      console.log("navbar connected");
    }

    function onDisconnect() {
      setIsConnected(false);
      console.log("navbar disconnected");
    }

    async function handleNewSms(message) {
      // Check if the received message is unread (isRead === false) and increment the notification count
      // if (!message.isRead) {
      //   setNotificationCount((prevNotifications) => [...prevNotifications, message]);
      // }
      const count = await getSmsUnreadCount();
      if (count === 0) {
        setNewSmsMessage(false);
      } else {
        setNewSmsMessage(true);
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    // Listen for 'newSms' event from the server
    socket.on("newSms", getUnreadSmsCount);

    // Listen for 'smsRead' event from the server
    // socket.on("smsRead", getUnreadSmsCount);

    // Listen for new Mockup msg
    socket.on("mockups-app", () => {
      console.log("mockup-app");
      setNewMockupMessage(true);

      // getUnreadMockupCount();
    });
    socket.on("mockupsRead", () => {
      getUnreadMockupCount();
    });

    return () => {
      // if (
      //   user?.permissions?.includes('sms_view') ||
      //   user?.permissions?.includes('sms_send') ||
      //   user?.roles === 'admin'
      // ) {
      //   getUnreadSmsCount();
      // }
      // socket.off("connect", onConnect);
      // socket.off("disconnect", onDisconnect);
      // socket.off("newSms", handleNewSms);
      // socket.off("mockups-app", getUnreadMockupCount);
    };
  }, [socket]);

  return (
    <>
      <div style={{ zIndex: 99999999 }} className="leftside-menu">
        <div
          className=" flex-center"
          style={{
            height: 120,
            backgroundColor: "linear-gradient(135deg, #8f75da 0%, #727cf5 60%)",
          }}
        >
          <Link to="/dashboard" className="">
            <div className="mt-1">
              {tempLogo ? (
                <img src={tempLogo} alt="logo" height="100px" width="150px" />
              ) : companyLogo ? (
                <ImgWithSignedUrl
                  path={companyLogo}
                  height="100px"
                  width="150px"
                />
              ) : (
                <img src={logo} alt="logo" height="100px" width="150px" />
              )}
            </div>
          </Link>
        </div>
        <Toast />

        <div className="" id="leftside-menu-container" data-simplebar>
          <ul className="side-nav" style={{ height: "98vh", overflow: "auto" }}>
            <li className="dropdown notification-list">
              <Link
                className="d-flex align-items-center justify-content-between side-nav-link"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span className="account-user-avatar">
                  <img
                    src={Avatar}
                    alt={`${user?.firstName} ${user?.lastName}`}
                    className="rounded-circle me-1"
                    height={30}
                    width={30}
                  />
                </span>
                <span className="text-white text-uppercase flex-grow-1">{`${user?.firstName} ${user?.lastName}`}</span>
                <span className="text-white text-uppercase">
                  <i className="mdi mdi-cog text-end"></i>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                <div className=" dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome !</h6>
                </div>

                <Link href="#" className="dropdown-item notify-item">
                  <i className="mdi mdi-account-circle me-1"></i>
                  <span>My Account</span>
                </Link>

                {user?.roles === "admin" && (
                  <Link className="dropdown-item notify-item" to="/settings">
                    <i className="mdi mdi-account-edit me-1"></i>
                    <span>Settings</span>
                  </Link>
                )}

                <Link href="#" className="dropdown-item notify-item">
                  <i className="mdi mdi-lifebuoy me-1"></i>
                  <span>Support</span>
                </Link>

                <Link href="#" className="dropdown-item notify-item">
                  <i className="mdi mdi-lock-outline me-1"></i>
                  <span>Lock Screen</span>
                </Link>

                <Link
                  href="#"
                  className="dropdown-item notify-item"
                  onClick={() => userLogout()}
                >
                  <i className="mdi mdi-logout me-1"></i>
                  <span>Logout</span>
                </Link>
              </div>
            </li>
            {user && user?.roles === "customer" && (
              <li className="" style={{ padding: "12px 30px" }}>
                <select
                  className="form-select"
                  value={user?.cid}
                  onChange={(e) => {
                    setSelectedCustomer(e.target.value);
                  }}
                >
                  {customersList?.map((item, i) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.customerName}
                    </option>
                  ))}
                </select>
              </li>
            )}
            <li className="side-nav-title side-nav-item">Navigation</li>
            {(user?.permissions?.includes("password_add") ||
              user?.permissions?.includes("password_update") ||
              user?.permissions?.includes("password_delete") ||
              user?.roles === "admin") && (
              <li>
                <Link to="password-manager" className="side-nav-link">
                  <i className="ri-lock-password-fill"></i>
                  <span> Password Manager </span>
                </Link>
              </li>
            )}
            {/* <li>
              {(user?.permissions?.includes("sms_view") ||
                user?.permissions?.includes("sms_send") ||
                user?.roles === "admin") && (
                <Link to="sms-messages" className="side-nav-link">
                  <i className="ri-discuss-fill"></i>
                  <span className="badge bg-danger float-end">
                    {newSmsMessage && `new ${smsUnreadCount}`}
                  </span>
                  <span> SMS Messages </span>
                </Link>
              )}
            </li> */}
            {(user?.permissions?.includes("mockups") ||
              user?.roles === "admin") && (
              <li className="side-nav-item">
                <Link to="mockups-list" className="side-nav-link">
                  <i className="ri-palette-fill"></i>
                  <span> Mockups</span>
                  <span className="badge bg-danger float-end">
                    {newMockupMessage && `new`}
                  </span>
                </Link>
              </li>

              // <li className="side-nav-item">
              //   <a
              //     data-bs-toggle="collapse"
              //     href="#mockupsMenu"
              //     aria-expanded="false"
              //     aria-controls="mockupsMenu"
              //     className="side-nav-link"
              //   >
              //     <i className="uil-copy-alt"></i>
              //     <span> Mockups </span>
              //     <span className="badge bg-danger float-end">
              //       {newMockupMessage && `new ${mockupsUnreadCount}`}
              //     </span>
              //     <span className="menu-arrow"></span>
              //   </a>
              //   <div className="collapse" id="mockupsMenu">
              //     <ul className="side-nav-second-level">
              //       <li>
              //         <Link to="mockups-list" className="side-nav-link">
              //           Mockups
              //         </Link>
              //       </li>
              //       <li>
              //         <Link to="mockups-catalogue" className="side-nav-link">
              //           Mockup Catalogue
              //         </Link>
              //       </li>
              //     </ul>
              //   </div>
              // </li>
            )}
            {/* <li>
              <Link to="designs" className="side-nav-link">
                <i className="mdi mdi-tshirt-crew"></i>
                Designs
              </Link>
            </li> */}
            {(user?.permissions?.includes("customer_add") ||
              user?.permissions?.includes("customer_update") ||
              user?.permissions?.includes("customer_delete") ||
              user?.roles === "admin") && (
              <li className="side-nav-item">
                <Link to="customer" className="side-nav-link">
                  <i className="ri-team-fill"></i>
                  <span> Customers </span>
                </Link>
              </li>
            )}
            {user?.roles === "customer" && (
              <li className="side-nav-item">
                <Link
                  to={`portal/${company?.name
                    ?.toLowerCase()
                    .replaceAll(" ", "-")}/work-order`}
                  className="side-nav-link"
                >
                  <i className="ri-file-paper-2-line"></i>
                  <span> Work Orders </span>
                </Link>
              </li>
            )}

            {(user?.permissions?.includes("quotes") ||
              user?.roles === "admin") && (
              <li>
                <Link
                  to="quotes/pending-processing?page=1"
                  className="side-nav-link"
                >
                  <i className="mdi mdi-format-list-numbered"></i>
                  <span> Quotes </span>
                </Link>
              </li>
            )}

            {(user?.permissions?.includes("work_orders_add") ||
              user?.permissions?.includes("work_orders_update") ||
              user?.permissions?.includes("work_orders_delete") ||
              user?.permissions?.includes("work_orders_dataEntry") ||
              user?.permissions?.includes("work_orders_dataEntry") ||
              user?.roles === "admin") && (
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#workOrderMenu"
                  aria-expanded="false"
                  aria-controls="workOrderMenu"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt"></i>
                  <span> Work Orders </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="workOrderMenu">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link
                        to="work-orders/pending-processing?page=1&limit=10"
                        className="side-nav-link"
                      >
                        <i className="mdi mdi-format-list-numbered"></i>
                        <span> WO List </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="status-board" className="side-nav-link">
                        <i className="mdi mdi-monitor-dashboard"></i>
                        <span>Job Board</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {(user?.permissions?.includes("bid_add") ||
              user?.permissions?.includes("bid_update") ||
              user?.permissions?.includes("bid_delete") ||
              user?.roles === "admin") && (
              <li className="side-nav-item">
                <Link to="bid-planner" className="side-nav-link">
                  <i className="ri-article-fill"></i>
                  <span> Bid Planner </span>
                </Link>
              </li>
            )}

            {user?.roles === "admin" && (
              <li className="side-nav-item">
                <Link k to="inbox" className="side-nav-link">
                  <i className="ri-article-fill"></i>
                  <span> Inbox </span>
                </Link>
              </li>
            )}

            {(user?.permissions?.includes("utility") ||
              user?.roles === "admin") && (
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#utilitiesMenu"
                  aria-expanded="false"
                  aria-controls="utilitiesMenu"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt"></i>
                  <span> Utilities </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="utilitiesMenu">
                  <ul className="side-nav-second-level">
                    {user?.roles === "admin" && (
                      <li>
                        <Link to="excel-functions"> Excel functions </Link>
                      </li>
                    )}

                    {(user?.permissions?.includes("tracking") ||
                      user?.roles === "admin") && (
                      <li>
                        <Link to="tracking"> Tracking </Link>
                      </li>
                    )}

                    <li>
                      <Link to="price-calculator"> Price Calculator </Link>
                    </li>
                    {user?.roles === "admin" && (
                      <li>
                        <Link to="attendance"> Attendance </Link>
                      </li>
                    )}
                    {user?.roles === "admin" && (
                      <li className="side-nav-item">
                        <Link to="pdf-test" className="side-nav-link">
                          <i className="uil-shopping-cart-alt"></i>
                          <span> Pdf Testing </span>
                        </Link>
                      </li>
                    )}
                    {user?.roles === "admin" && (
                      <li>
                        <Link to="electron-test" className="side-nav-link">
                          <i className="ri-lock-password-fill"></i>
                          <span> Electron Testing </span>
                        </Link>
                      </li>
                    )}
                    {(user?.permissions?.includes("how_to_videos") ||
                      user?.roles === "admin") && (
                      <li>
                        <Link to="how-to-videos"> How To Videos </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div
          className="text-white "
          style={{
            position: "absolute",
            bottom: "0%",
            left: "15%",
          }}
        >
          <h5> Version : 0.4.5 </h5>
        </div>
      </div>
    </>
  );
}

export default NavBar;

import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import { v4 as uuid } from "uuid";

export default function DesignImg({
  selectedImg,
  selectedProduct,
  updateSelectedProduct,
  selectedComment,
}) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [imgSrc, setImgSrc] = useState(null);

  const [showLargeImgModal, setShowLargeImgModal] = useState(false);

  /*   All Functions
   ********************************************* */

  const urlToBase64 = async (url) => {
    // Fetch the image as a blob
    const response = await fetch(url);
    const blob = await response.blob();
    // Create a file reader object
    const reader = new FileReader();
    // Return a promise that resolves with the base64 string
    const base64Url = await new Promise((resolve, reject) => {
      // Set the onloadend event handler
      reader.onloadend = async () => {
        // Get the base64 string from the result
        const base64 = reader.result;
        // Resolve the promise with the base64 string
        resolve(base64);
      };
      // Set the onerror event handler
      reader.onerror = reject;
      // Read the blob as a data URL
      reader.readAsDataURL(blob);
    });
    // console.log(base64Url)

    return base64Url;
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedImg) {
      const getImageUrl = async () => {
        const { data } = await API.post(
          "/design-request/get-image",
          { path: selectedImg.img },
          {
            headers: authHeader(),
          }
        );
        let url = await urlToBase64(data);
        setImgSrc(url);
        return data;
      };

      getImageUrl();
    }
  }, [selectedImg]);

  if (!selectedImg || !imgSrc) return <Spinner />;

  return (
    <div className="flex-grow-1 flex-center">
      <div className="" style={{ maxWidth: 950, height: "65vh" }}>
        <img
          // onClick={() => setShowLargeImgModal(true)}
          className=""
          src={imgSrc}
          style={{ width: "100%", height: "100%", objectFit: "" }}
          alt={"product"}
        />
      </div>

      {/*   Comment Modal
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent"
        show={showLargeImgModal}
        onHide={() => setShowLargeImgModal(false)}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <h3>{selectedComment ? "Update" : "Add"} Message</h3>
          sfsd
        </Modal.Body>
      </Modal>
    </div>
  );
}

import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import API from "services/axios";
import FabricSelectModal from "../FabricSelectModal";

export default function CustomProduct({
  CheckMails,
  selectedDesign,
  setSelectedDesign,
  setDesignRequests,
  setShowAddModal,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showFabricsModal, setShowFabricsModal] = useState(false);
  const [selectedFabric, setSelectedFabric] = useState(null);

  /*   All Functions
   ********************************************* */
  const uploadProduct = async (e, sendEmail) => {
    if (sendEmail && !selectedDesign?.email) {
      return dispatch(
        setNotification({
          message: "Please Add customer email",
          type: "error",
        })
      );
    }

    if (!formData?.file) {
      return dispatch(
        setNotification({
          type: "error",
          message: "Please upload file.",
        })
      );
    }
    if (!formData?.title) {
      return dispatch(
        setNotification({
          type: "error",
          message: "Please enter title.",
        })
      );
    }

    setIsLoading(true);

    try {
      const submitData = new FormData();
      submitData.append("file", formData?.file);

      let productId =
        selectedDesign?.products && selectedDesign?.products?.length
          ? selectedDesign?.products[selectedDesign?.products?.length - 1].id +
            1
          : 1;

      let folder = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${productId}`;

      submitData.append("locationId", selectedDesign?.customer?.locationId);
      submitData.append(
        "locationName",
        selectedDesign?.customer?.location?.locationName
      );
      submitData.append(
        "locationLogo",
        selectedDesign?.customer?.location?.logo
      );
      submitData.append(
        "isFirstProduct",
        selectedDesign?.products?.length > 0 ? false : true
      );
      submitData.append("mockupTitle", selectedDesign?.title);
      submitData.append("sendEmail", sendEmail);
      submitData.append("emailTo", selectedDesign?.email);

      submitData.append("folder", folder);
      submitData.append("id", selectedDesign.id);

      // /* ******************** Updating currentData  ************************* */
      let imgUrl = `${folder}/${formData?.file?.name}`;
      let newImgData = [
        {
          img: imgUrl,
          version: 1,
        },
      ];

      let newProduct = {
        id: productId,
        title: formData?.title,
        hasPlayerNames: formData?.hasPlayerNames,
        hasNumbers: formData?.hasNumbers,
        description: formData?.description,
        fabrics: selectedFabric,
        images: newImgData,
        comments: [],
      };

      let updatedDesignProducts = selectedDesign?.products
        ? [...selectedDesign?.products, newProduct]
        : [newProduct];

      let updatedSelectedDesign = {
        ...selectedDesign,
        products: updatedDesignProducts,
      };

      submitData.append("products", JSON.stringify(updatedDesignProducts));

      /* ******************** Api Call  ************************* */
      const { data } = await API.post(
        "/design-request/new-product",
        submitData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setSelectedDesign((prev) => updatedSelectedDesign);
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item?.id === updatedSelectedDesign?.id) {
            return updatedSelectedDesign;
          } else {
            return item;
          }
        });
      });
      CheckMails();
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);

      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      setIsLoading(false);
    }

    setShowAddModal(false);
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        title: `Design ${
          selectedDesign?.products?.length
            ? selectedDesign?.products?.length + 1
            : 1
        }`,
      };
    });
  }, [selectedDesign]);

  if (!formData?.title) return <Spinner />;

  return (
    <div>
      {isLoading && <Spinner />}
      <form>
        <div className="row mb-2">
          <label className="col-3 my-auto">Design Name</label>
          <div className="col-9 d-flex align-items-center gap-1">
            <input
              type="text"
              required
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, title: e.target.value };
                });
              }}
              value={formData.title}
              className="form-control"
              placeholder="Enter Design Name"
            />
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-3 my-auto">File</label>
          <div className="col-9 ">
            <input
              type="file"
              required
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, file: e.target.files[0] };
                });
              }}
              className="form-control"
              placeholder="Enter Title"
            />
          </div>
        </div>

        {/*   buttons
         ********************************************* */}
        <div className="my-2">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                setShowAddModal(false);
              }}
              className="btn btn-secondary btn-sm"
              type="button"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm ms-1"
              onClick={(e) => {
                uploadProduct(e, false);
              }}
            >
              Add
            </button>
            <button
              type="button"
              onClick={(e) => {
                uploadProduct(e, true);
              }}
              className="btn btn-primary btn-sm ms-1"
            >
              Send To Customer
            </button>
          </div>
        </div>
      </form>
      <Modal
        size="sm"
        show={showFabricsModal}
        onHide={() => setShowFabricsModal(false)}
      >
        <Modal.Body
          className="custom-shadow bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <FabricSelectModal
            selectedFabric={selectedFabric}
            showModal={setShowFabricsModal}
            setSelectedFabric={setSelectedFabric}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

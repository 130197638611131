// import React from "react";

// export default function Designs() {
//   /*   All States
//    ********************************************* */
//   let designData = [{
//     id:1,
//     name:"Design 1",
//     customerId:1,
//     customerName:"Customer 1",
//     designType:"Design Type 1",

//   }]

//   /*   All Functions
//    ********************************************* */

//   /*   All UseEffects
//    ********************************************* */
//   return (
//     <div
//       className=""
//       style={{
//         marginLeft: 280,
//         height: "90vh",
//         paddingRight: 30,
//       }}
//     >
//       Designs
//     </div>
//   );
// }

import { Listbox } from "@headlessui/react";
import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function Designs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  /*   All States
   ********************************************* */

  const [designData, setDesignData] = useState([
    {
      id: 1,
      name: "Design 1",
      customer: {
        id: 12,
        customerName: "Customer 1",
      },
      designType: "Design Type 1",
      designVariations: [
        {
          id: 1,
          designCode: "Code 1",
          color: "Red",
          styleNo: "STY-001",
          sizingCut: "Raglan",
          productImg: ",",
          custom1: "111",
          custom2: "222",
          custom3: "333",
        },
      ],
    },
  ]);
  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */

  let loadingOn = () => {
    setLoading(true);
  };

  let loadingOff = () => {
    setLoading(false);
  };

  const getDesignData = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/licensespring-customers", {
        headers: authHeader(),
      });
      setDesignData(data);
      console.log(data);

      loadingOff();
    } catch (err) {
      console.log(err?.response?.data?.message);
      Notification("error", err?.response?.data?.message);
      loadingOff();
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    // getDesignData();
  }, []);

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      <>
        <button
          onClick={() => Notification("success", "Working on it!")}
          type="button"
          className="btn ms-2 btn-primary"
        >
          ADD CUSTOMER
        </button>
      </>
    </div>
  );

  const table_head = ["ID", "Name", "Customer Name", "Design Type", "Action"];

  const table_body = (item, index) => (
    <tr className="border-b-2 hover:bg-white duration-300 " key={item.id}>
      <td className="align-middle py-1">{item?.id}</td>
      <td className="align-middle py-1">{item?.name}</td>
      <td className="align-middle py-1">{item?.customer?.customerName}</td>
      <td className="align-middle py-1">{item?.designType}</td>
      <td className="align-middle py-1 ">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            navigate("details/" + item.id);
          }}
        >
          View
        </button>
      </td>
    </tr>
  );
  return (
    <div
      className="mx-2"
      style={{ paddingLeft: 280, paddingTop: 20, height: "90vh" }}
    >
      <h3 className="pt-3">Designs</h3>
      />
      {loading && <Spinner />}
      {designData && (
        <>
          <Table
            btn1={btn1}
            table_head={table_head}
            table_body={table_body}
            table_data={designData}
          />
        </>
      )}
    </div>
  );
}

import { useEffect, useState } from "react";

import { setNotification } from "features/Notification/notificationSlice";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import AddNewVersion from "./AddNewVersion";
import CommentSection from "./components/CommentSection/CommentSection";
import DesignImg from "./components/DesignImg";
import ProductSlider from "./components/ProductSection/ProductSlider";

import Spinner from "components/Spinner";
import ReactQuill from "react-quill";
import EmailSection from "./EmailSection/EmailSection";
import ProductEditModal from "./ProductEditModal";

export default function MockupDetails({
  selectedDesign,
  designRequests,
  setSelectedDesign,
  setDesignRequests,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);

  const [mockupFabrics, setMockupFabrics] = useState(null);

  const [showProductEditModal, setShowProductEditModal] = useState(null);
  const [showAddVersionModal, setShowAddVersionModal] = useState(false);

  const [selectedComment, setSelectedComment] = useState(null);
  const [notes, setNotes] = useState(false);
  const [showNotes, setShowNotes] = useState("");

  const [activeInComment, setActiveInComment] = useState("comments");

  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [selectedDesignEmails, setSelectedDesignEmails] = useState([]);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   All Functions
   ********************************************* */

  const CheckMails = async () => {
    if (!selectedDesign) {
      return;
    }

    try {
      setEmailLoading(true);
      const { data } = await API.post(
        "/emails/new-emails",
        {
          locationId: selectedDesign.customer.locationId,
          mockupId: selectedDesign.id,
        },
        {
          headers: authHeader(),
        }
      );
      if (data?.success) {
        dispatch(setNotification({ type: "success", message: data.message }));

        setSelectedDesignEmails(data.selectedDesignEmails);
      } else if (!data.success) {
        dispatch(setNotification({ type: "error", message: data.message }));
      } else {
        dispatch(
          setNotification({
            type: "error",
            message: "Something went wrong CheckLogs",
          })
        );
      }

      setEmailLoading(false);
    } catch (err) {
      setEmailLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const updateSelectedImg = (img) => {
    setSelectedImg((prev) => null);
    setTimeout(() => setSelectedImg((prev) => img), 100);
  };

  const changeImageByCommentVersion = (imageVersion) => {
    if (selectedImg?.version !== imageVersion) {
      updateSelectedImg(selectedImg);
      setSelectedImgIndex((prev) => {
        return selectedProduct?.images?.findIndex(
          (item) => item.version === imageVersion
        );
      });
    }
  };

  const updateSelectedState = (newState, imageVersion) => {
    changeImageByCommentVersion(imageVersion);
  };

  const updateSelectedProduct = async (product, commentPath) => {
    setSelectedProduct((prev) => null);
    setTimeout(() => {
      setSelectedProduct((prev) => product);
    }, 100);

    let updatedSelectedDesign = null;
    setDesignRequests((prev) => {
      return prev.map((item) => {
        if (item.id === selectedDesign.id) {
          let updatedProducts = item.products.map((prod) => {
            if (prod.id === product.id) {
              return product;
            } else {
              return prod;
            }
          });

          let newItem = { ...item, products: updatedProducts };
          updatedSelectedDesign = newItem;
          setSelectedDesign((prev) => newItem);
          return newItem;
        } else {
          return item;
        }
      });
    });

    /*   update Api Call
     ********************************************* */

    const { data } = await API.patch(
      "/design-request/update",
      {
        formData: {
          ...updatedSelectedDesign,

          products: JSON.stringify(updatedSelectedDesign?.products),
        },
        path: commentPath ? commentPath : null,
      },
      { headers: authHeader() }
    );
  };

  const handleUpdateNotes = async () => {
    let updatedProduct = { ...selectedProduct, notes };
    await updateSelectedProduct(updatedProduct);
    setShowNotes(false);
  };

  const updateRead = async (id) => {
    try {
      const { data } = await API.post(
        "/design-request/update-read",
        { id },
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      return data?.count;
    } catch (err) {
      console.log(err.message);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getAllMockupFabrics = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-fabrics", {
        headers: authHeader(),
      });

      if (data?.type === "success") {
        let resultData = data.data;
        setMockupFabrics(resultData);
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getAllMockupFabrics();
  }, []);

  useEffect(() => {
    setNotes((prev) => selectedProduct?.notes);
    updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
    setSelectedImgIndex(0);
    return () => updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
  }, [selectedProduct]);

  useEffect(() => {
    updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
    return () => updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
  }, [selectedImgIndex]);

  useEffect(() => {
    if (selectedDesign) {
      setSelectedDesignEmails(selectedDesign?.designRequestsEmails);
    }
    if (selectedDesign && !selectedDesign?.isRead) {
      updateRead(selectedDesign.id);
    }
  }, [selectedDesign]);

  useEffect(() => {
    let timeOutID = setTimeout(() => {
      CheckMails();
    }, 100);

    // Set interval for 15 min to run CheckMails and cleaup when component dismounts
    const intervalId = setInterval(() => {
      CheckMails();
    }, 15 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeOutID);
    };
  }, []);

  return (
    <div className="d-flex gap-3 mt-2" style={{ height: "85vh" }}>
      {loading && <Spinner />}
      <div className="w-75 d-flex flex-column gap-2">
        {/*   Top Header Section
         ********************************************* */}

        {/* Pdf Catalogue options */}
        <div className="bg-white shadow-sm px-3 custom-border-radius py-1 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between gap-2 align-items-center">
            <h4>
              {selectedProduct?.title ? (
                <div className="d-flex align-items-center">
                  <button className="btn btn-primary btn-sm me-2">
                    {selectedProduct?.id}
                  </button>
                  {selectedProduct?.title}
                </div>
              ) : (
                "Please Add Product"
              )}
            </h4>
          </div>
          <div className="flex-grow-1 gap-1 ms-3"></div>
          <div className="d-flex align-items-center ">
            {selectedProduct && (
              <>
                <button
                  onClick={() => {
                    setShowAddVersionModal(true);
                  }}
                  className="btn btn-sm btn-primary btn-primary"
                >
                  Add Version
                </button>

                <button
                  type="button"
                  className="btn btn-success btn-sm
             dropdown-toggle ms-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Version {selectedImg?.version}
                </button>
                <div className="dropdown-menu">
                  {selectedProduct?.images?.map((img, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (index !== selectedImgIndex) {
                          setSelectedImgIndex((prev) => index);
                          setSelectedComment(null);
                        }
                      }}
                      className="dropdown-item"
                      href="#"
                    >
                      Version {img?.version}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        {/*   Image Section
         ********************************************* */}
        <div className=" d-flex justify-content-between gap-3">
          {/*   Product Slider
           ********************************************* */}
          <ProductSlider
            CheckMails={CheckMails}
            selectedDesign={selectedDesign}
            setSelectedProduct={setSelectedProduct}
            selectedProduct={selectedProduct}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
            updateSelectedProduct={updateSelectedProduct}
          />
          {/*   Image
           ********************************************* */}
          <div className="bg-white overflow-auto custom-border-radius flex-center flex-grow-1 ">
            {selectedImg && (
              <DesignImg
                selectedImg={selectedImg}
                selectedProduct={selectedProduct}
                updateSelectedState={updateSelectedState}
                updateSelectedProduct={updateSelectedProduct}
                selectedComment={selectedComment}
                setSelectedComment={setSelectedComment}
              />
            )}
          </div>
        </div>
      </div>
      {/*    Comment Section
       ********************************************* */}

      <div className="px-3 pb-3 w-25 d-flex flex-column bg-white bg-white shadow-lg custom-border-radius ">
        {/* <h3 className="my-2">Comments</h3> */}
        <ul className="nav nav-tabs mb-2 nav-bordered border-0 d-flex flex-grow-1 py-2">
          <li
            onClick={() => {
              setActiveInComment("comments");
            }}
            className="nav-item"
          >
            <button
              className={
                activeInComment === "comments"
                  ? `nav-link rounded-0 active bg-primary text-white py-1`
                  : "nav-link bg-light shadow-sm rounded-0 py-1"
              }
            >
              <span className="d-none d-md-block">Comments</span>
            </button>
          </li>
          <li
            onClick={() => {
              setActiveInComment("email");
            }}
            className="nav-item"
          >
            <button
              className={
                activeInComment === "email"
                  ? `nav-link rounded-0 active bg-primary text-white py-1`
                  : "nav-link bg-light shadow-sm rounded-0 py-1"
              }
            >
              Email
              {emailLoading && (
                <span
                  class="spinner-border spinner-border-sm ms-2 py-0"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </li>
        </ul>

        {activeInComment === "comments" && (
          <CommentSection
            selectedDesign={selectedDesign}
            selectedProduct={selectedProduct}
            selectedImg={selectedImg}
            selectedComment={selectedComment}
            designRequests={designRequests}
            setDesignRequests={setDesignRequests}
            setSelectedDesign={setSelectedDesign}
            setSelectedProduct={setSelectedProduct}
            updateSelectedProduct={updateSelectedProduct}
            updateSelectedState={updateSelectedState}
            setSelectedComment={setSelectedComment}
          />
        )}
        {activeInComment === "email" && (
          <EmailSection
            CheckMails={CheckMails}
            selectedDesignEmails={selectedDesignEmails}
            selectedDesign={selectedDesign}
          />
        )}
      </div>
      {/*   ADD Product Notes
       ********************************************* */}
      <Modal
        show={showNotes}
        contentClassName="bg-transparent"
        onHide={() => setShowNotes(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <h2 className="mb-3">Product Notes</h2>
          {/*   Notes
           ********************************************* */}
          <div
            className="bg-white shadow-lg p-2 my-3 me-2 d-flex flex-column justify-content-between "
            style={{ borderRadius: 15, minHeight: 300 }}
          >
            <div className="">
              <ReactQuill
                modules={modules}
                theme="snow"
                value={notes}
                onChange={(val) => setNotes(val)}
              />
            </div>
          </div>

          {/*   buttons
           ********************************************* */}
          <div className="my-2">
            <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  setShowNotes(false);
                  setNotes("");
                }}
                className="btn btn-secondary btn-sm"
                type="button"
              >
                Close
              </button>

              <button
                className="btn btn-primary btn-sm ms-1"
                onClick={handleUpdateNotes}
                type="submit"
              >
                UPDATE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*   ADD New Version Modal
       ********************************************* */}
      <Modal
        show={showAddVersionModal}
        onHide={() => setShowAddVersionModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <AddNewVersion
            CheckMails={CheckMails}
            setShowAddModal={setShowAddVersionModal}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
          />
        </Modal.Body>
      </Modal>

      {/*   Edit Product
       ********************************************* */}
      <Modal
        show={showProductEditModal}
        contentClassName="bg-transparent"
        onHide={() => setShowProductEditModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <ProductEditModal
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
            mockupFabrics={mockupFabrics}
            showModal={setShowProductEditModal}
            selectedProduct={selectedProduct}
            updateSelectedProduct={updateSelectedProduct}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import API from "services/axios";
import authHeader from "services/auth-header";
import Modal from "react-bootstrap/Modal";
import ImportContacts from "./ImportContacts";
import axios from "axios";
import CustomerDetails from "./CustomerDetails";
import CustomerFiles from "./CustomerFile";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import CustomerWorkOrder from "./CustomerWorkOrder/CustomerWorkOrder";
import C_Quotes from "./C_Quotes/C_Quotes";

function CustomerDetailsLayout({
  searchResult,
  selectedId,
  setSearchResult,
  setShowDetailsPage,
}) {
  /*   All States
   ********************************************* */

  const { clearCustomerData, customerContextData } = useWorkOrder();

  const [allCustomersData, setAllCustomersData] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [index, setIndex] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const [showImportModal, setShowImportModal] = useState(false);

  useEffect(() => {
    setSelectedCustomerId(selectedId);
  }, [selectedId]);

  const [customerContactData, setCustomerContactData] = useState(null);
  const [customersAddressData, setCustomersAddressData] = useState(null);

  const [active, setActive] = useState(
    customerContextData ? "work Order" : "customer_details"
  );
  const checkActive = (val) => {
    setActive(val);
  };

  /*   All Function
   ********************************************* */

  const getAllCustomersData = async (id) => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setAllCustomersData(data);
      if (id) {
        setSelectedCustomerId(id);
      }
      return data;
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCustomerInfo = async () => {
    try {
      const { data } = await API.post(
        "/customerinfo",
        { id: selectedCustomer?.id },
        {
          headers: authHeader(),
        }
      );
      setCustomerContactData(parseJsonData(data.contacts));
      setCustomersAddressData(parseJsonData(data.addresses));
    } catch (err) {
      console.log(err.message);
    }
  };

  const parseJsonData = (data) => {
    let newArray = [];
    data.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  const previousCustomer = () => {
    if (index == 0) {
      return;
    }
    setIndex((prev) => prev - 1);
  };

  const nextCustomer = () => {
    if (index == allCustomersData.length - 1) {
      return;
    }
    setIndex((prev) => prev + 1);
  };

  const downloadSampleFile = () => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: "kms/customer/Customer Info - Sample-.xlsx",
      })
      .then((response) => {
        const url = response.data[0];
        const link = document.createElement("a");
        link.href = url;
        link.download = "Customer Info - Sample-.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  useEffect(() => {
    if (selectedCustomer) {
      getCustomerInfo();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (searchResult) {
      setAllCustomersData(searchResult);
    } else {
      getAllCustomersData();
    }
  }, [searchResult]);

  useEffect(() => {
    if (allCustomersData) {
      allCustomersData?.some((item, index) => {
        if (item.id === selectedCustomerId) {
          setIndex(index);
          return item.id === selectedCustomerId;
        }
      });
    }
  }, [allCustomersData, selectedCustomerId, searchResult]);

  useEffect(() => {
    if (allCustomersData) {
      setSelectedCustomer(allCustomersData[index]);
    }
    return () => {
      clearCustomerData();
    };
  }, [index, allCustomersData]);

  const copyContent = (val) => {
    navigator.clipboard.writeText(val);
  };

  return (
    <div
      className="flex-grow-1 d-flex flex-column"
      style={{ position: "relative" }}
    >
      {/*   Top Nav For Customer
       ********************************************* */}
      <div
        className="d-flex justify-content-end align-items-center bg-white shadow-lg ps-5 pe-4 py-2 z-3"
        style={{
          borderRadius: "10px",
          position: "fixed",
          top: 0,
          width: "calc(100vw - 200px)",
          marginLeft: 200,
          zIndex: 50,
        }}
      >
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            ID : &nbsp; {selectedCustomer?.customerId}
            <div className="ms-3 d-flex align-items-center">
              <h4
                onDoubleClick={() => {
                  copyContent(selectedCustomer?.customerName?.toUpperCase());
                }}
                className="user-select-none"
              >
                {selectedCustomer?.customerName?.toUpperCase()}
              </h4>
            </div>
          </div>
        </div>
        <button
          onClick={previousCustomer}
          className="btn btn-success btn-sm my-1 px-2 py-0  "
        >
          <i className="mdi mdi-arrow-left-bold fs-4 py-0 my-0"></i>
        </button>
        <button
          onClick={nextCustomer}
          className="btn btn-success ms-1 me-2 btn-sm my-1 px-2 py-0  "
        >
          <i className="mdi mdi-arrow-right-bold fs-4 py-0 my-0"></i>
        </button>

        <button
          className="btn my-1 mx-1 btn-success btn-sm"
          onClick={() => {
            setShowImportModal(true);
          }}
        >
          IMPORT
        </button>
        <button
          onClick={() => downloadSampleFile()}
          type="button"
          className="btn btn-sm px-2 btn-info me-1"
        >
          <i className="mdi mdi-download"></i> SAMPLE FILE
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => {
            setShowDetailsPage(false);
            setSearchResult(null);
            clearCustomerData();
          }}
        >
          Back
        </button>
      </div>
      {/* Main Content */}
      <div className="d-flex px-2 " style={{ marginLeft: 260, marginTop: 80 }}>
        <div className=" d-flex flex-column flex-grow-1">
          <div
            className="flex-grow-1 d-flex flex-column mt-2"
            style={{ borderRadius: 10 }}
          >
            {/* Customer Details Tabs */}
            <ul className="nav nav-tabs nav-bordered mb-2  d-flex flex-grow-1">
              <li
                onClick={() => {
                  checkActive("customer_details");
                }}
                className="nav-item"
              >
                <button
                  className={
                    active === "customer_details"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <span className="d-none d-md-block">Customer Details</span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("file_manager");
                }}
                className=""
              >
                <button
                  className={
                    active === "file_manager"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">File Manager</span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("quotes");
                }}
                className=""
              >
                <button
                  className={
                    active === "quotes"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">Quotes</span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("work Order");
                }}
                className=""
              >
                <button
                  className={
                    active === "work Order"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">Work Orders</span>
                </button>
              </li>
            </ul>
            {/* Customer Tabs Content */}
            <div className="d-flex flex-grow-1">
              {active === "customer_details" &&
                allCustomersData &&
                selectedCustomer && (
                  <CustomerDetails
                    customerContactData={customerContactData}
                    customersAddressData={customersAddressData}
                    selectedCustomer={selectedCustomer}
                    allCustomersData={allCustomersData}
                    getCustomerInfo={getCustomerInfo}
                    getAllCustomersData={getAllCustomersData}
                  />
                )}
              {active === "file_manager" && (
                <div
                  className=" d-flex flex-grow-1 bg-white shadow-lg px-4"
                  style={{ borderRadius: 10 }}
                >
                  <CustomerFiles selectedCustomer={selectedCustomer} />
                </div>
              )}
              {active === "quotes" && selectedCustomer && (
                <div
                  className=" d-flex flex-grow-1  justify-content-start bg-white shadow-lg "
                  style={{ borderRadius: 10 }}
                >
                  <C_Quotes id={selectedCustomer?.id} />
                </div>
              )}
              {active === "work Order" && selectedCustomer && (
                <div
                  className=" d-flex flex-grow-1  justify-content-start bg-white shadow-lg "
                  style={{ borderRadius: 10 }}
                >
                  <CustomerWorkOrder id={selectedCustomer?.id} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*   Import Modal
       ********************************************* */}
      <Modal
        show={showImportModal}
        size="lg"
        onHide={() => setShowImportModal(false)}
      >
        <Modal.Body>
          <ImportContacts
            id={selectedCustomer?.id}
            customerContactData={customerContactData}
            customersAddressData={customersAddressData}
            refreshFunc={getCustomerInfo}
            setShowImportModal={setShowImportModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomerDetailsLayout;

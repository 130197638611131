import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import ImageWIthSignedUrl from "../ImageWIthSignedUrl";

export default function AddNewVersion({
  CheckMails,
  setShowAddModal,
  selectedProduct,
  selectedDesign,
  setDesignRequests,
  setSelectedDesign,
  setSelectedProduct,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [isLoading, setIsLoading] = useState(false);

  const [filesList, setFilesList] = useState([]);

  /*   All Functions
   ********************************************* */
  const addNewProductVersion = async (e, sendEmail) => {
    e.preventDefault();

    if (!filesList.length) {
      return dispatch(
        setNotification({
          message: "Please select at least one file",
          type: "error",
        })
      );
    }

    if (sendEmail && !selectedDesign?.email) {
      return dispatch(
        setNotification({
          message: "Please Add customer email",
          type: "error",
        })
      );
    }

    setIsLoading(true);

    try {
      const submitData = new FormData();
      // submitData.append("file", formData?.file);
      for (let i = 0; i < filesList.length; i++) {
        submitData.append("files", filesList[i]?.file);
      }
      submitData.append("locationId", selectedDesign?.customer?.locationId);
      submitData.append(
        "locationName",
        selectedDesign?.customer?.location?.locationName
      );
      submitData.append(
        "locationLogo",
        selectedDesign?.customer?.location?.logo
      );
      submitData.append("mockupTitle", selectedDesign?.title);
      submitData.append("sendEmail", sendEmail);
      submitData.append("emailTo", selectedDesign?.email);

      submitData.append("id", selectedDesign.id);

      /* ******************** Updating currentData  ************************* */

      /**
       * Loop through filesList and update following data
       * for each productId in filesList
       */

      // initialize updatedDesignProducts to update all products.
      let updatedDesignProducts = [...selectedDesign?.products];

      let updatedFilesList = filesList.map((item) => {
        let currentProduct = selectedDesign?.products?.find(
          (product) => product.id === item.id
        );

        let folder = `kms/design-requests/${user?.companyId}/${
          selectedDesign?.customerId
        }/${selectedDesign?.title.replaceAll(
          " ",
          "_"
        )}/${currentProduct?.title.replaceAll(" ", "_")}`;
        let imgUrl = `${folder}/${item.fileName}`;

        // Create Append imgPath of new image to currentProduct Images.
        let newImgData = [
          {
            img: imgUrl,
            version: currentProduct.images?.length + 1,
          },
          ...currentProduct.images,
        ];

        // Update Selected Product to included new image.
        let updatedCurrentProduct = {
          ...currentProduct,
          images: newImgData,
        };

        // Update Project to included updated product.
        updatedDesignProducts = updatedDesignProducts.map((product) => {
          if (product.id === updatedCurrentProduct?.id) {
            return updatedCurrentProduct;
          }
          return product;
        });

        return {
          ...item,
          folder,
          imgUrl,
        };
      });

      let updatedSelectedDesign = {
        ...selectedDesign,
        products: updatedDesignProducts,
      };

      submitData.append("filesList", JSON.stringify(updatedFilesList));
      submitData.append("products", JSON.stringify(updatedDesignProducts));

      /* ******************** Api Call  ************************* */
      const { data } = await API.post(
        "/design-request/new-version",
        submitData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      let updatedSelectedProduct = updatedSelectedDesign?.products?.find(
        (product) => product.id === selectedProduct?.id
      );
      setSelectedProduct((prev) => updatedSelectedProduct);
      setSelectedDesign((prev) => updatedSelectedDesign);
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item?.id === updatedSelectedDesign?.id) {
            return updatedSelectedDesign;
          } else {
            return item;
          }
        });
      });
      CheckMails();
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      setIsLoading(false);
    }

    setShowAddModal(false);
  };

  const handleFileSelect = (e) => {
    let { id, name } = e.target;
    id = parseInt(id);
    let file = e.target.files[0];
    // Only Allow png, jpg, jpeg files
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      e.target.value = null;
      return dispatch(
        setNotification({
          message: "Only png, jpg, jpeg files are allowed",
          type: "error",
        })
      );
    }

    let fileName = file.name;

    // Check if file with same id is present
    let isPresent = filesList.find((item) => item.id === id);
    if (isPresent) {
      let updatedFilesList = filesList.map((item) => {
        if (item.id === id) {
          return { ...item, file, fileName };
        }
        return item;
      });
      setFilesList(updatedFilesList);
    } else {
      setFilesList((prev) => {
        return [
          ...prev,
          {
            id,
            title: name,
            file,
            fileName,
          },
        ];
      });
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {}, []);

  return (
    <div>
      {isLoading && <Spinner />}
      <div className="">
        <h3>Add New Version ( width 950px ) </h3>
      </div>
      <hr className="mt-0" />
      <div className="row">
        <div className="col-12">
          <form>
            {selectedDesign?.products?.map((product, index) => (
              <div className=" mb-3">
                <h4 className="form-label">{product?.title}</h4>
                <div className="">
                  <input
                    type="file"
                    name={product?.title}
                    id={product?.id}
                    onChange={handleFileSelect}
                    className="form-control"
                    placeholder="Enter Title"
                  />
                </div>
              </div>
            ))}
            {/*   buttons
             ********************************************* */}
            <div className="my-2 mt-4">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowAddModal(false);
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                <button
                  onClick={(e) => {
                    addNewProductVersion(e, false);
                  }}
                  className="btn btn-primary btn-sm ms-1"
                  type="submit"
                >
                  Add Version
                </button>
                <button
                  onClick={(e) => {
                    addNewProductVersion(e, true);
                  }}
                  className="btn btn-primary btn-sm ms-1"
                  type="submit"
                >
                  Send To Customer
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

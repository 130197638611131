import React, { useEffect, useState } from "react";
import CustomerDetailsColumn from "./CustomerDetails/CustomerDetailsColumn";
import API from "services/axios";
import authHeader from "services/auth-header";
import { useDispatch } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";
import AccountPayable from "./AccountPayable/AccountPayable";
import CustomerContact from "./CustomerContact/CustomerContact";
import GeneralSalesDetails from "./generalSalesDetails/GeneralSalesDetails";
import CustomersAddress from "./customerAddress/CustomerAddress";
import Spinner from "components/Spinner";

function CustomerDetails({
  customerContactData,
  customersAddressData,
  allCustomersData,
  selectedCustomer,
  getCustomerInfo,
  getAllCustomersData,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();

  const [taxAccounts, setTaxAccounts] = useState(null);

  const [active, setActive] = useState("payableAccount");
  const checkActive = (val) => {
    setActive(val);
  };

  /*   All Function
   ********************************************* */

  // get Tax Accounts
  const getAllTaxAccount = async () => {
    try {
      const { data } = await API.get("/taxrate", { headers: authHeader() });
      setTaxAccounts(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    getAllTaxAccount();
  }, []);

  return (
    <div className="flex-grow-1 d-flex flex-column">
      {allCustomersData && selectedCustomer ? (
        <div className="d-flex">
          <div className=" d-flex flex-column flex-grow-1">
            <div
              className="flex-grow-1 bg-white shadow-sm px-4 py-3"
              style={{ borderRadius: 10 }}
            >
              <CustomerContact
                id={selectedCustomer?.id}
                customerContactData={customerContactData}
                getCustomerInfo={getCustomerInfo}
              />
            </div>
            <div
              className="flex-grow-1 d-flex flex-column mt-2"
              style={{ borderRadius: 10 }}
            >
              <ul className="nav nav-tabs nav-bordered mb-2  d-flex flex-grow-1">
                <li
                  onClick={() => {
                    checkActive("payableAccount");
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "payableAccount"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Account Details</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    checkActive("customer_address");
                  }}
                  className=""
                >
                  <button
                    className={
                      active === "customer_address"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                    <span className="d-none d-md-block">Shipping Address</span>
                  </button>
                </li>
              </ul>
              <div className="d-flex flex-grow-1">
                {active === "payableAccount" && selectedCustomer && (
                  <div
                    className=" d-flex flex-grow-1 flex-column align-items-start w-50 py-2 bg-white shadow-sm px-4"
                    style={{ borderRadius: 10 }}
                  >
                    <AccountPayable
                      customerDetails={selectedCustomer}
                      taxAccounts={taxAccounts}
                      refreshFunc={getAllCustomersData}
                    />
                  </div>
                )}
                {active === "payableAccount" && (
                  <div
                    className=" d-flex flex-grow-1 align-items-start w-50 py-2 ms-2 bg-white shadow-sm px-4"
                    style={{ borderRadius: 10 }}
                  >
                    <GeneralSalesDetails
                      taxAccounts={taxAccounts}
                      customerDetails={selectedCustomer}
                      refreshFunc={getAllCustomersData}
                    />
                  </div>
                )}
                {active === "customer_address" && (
                  <div
                    className=" d-flex flex-grow-1 bg-white shadow-sm px-4"
                    style={{ borderRadius: 10 }}
                  >
                    {selectedCustomer && (
                      <CustomersAddress
                        id={selectedCustomer?.id}
                        customersAddressData={customersAddressData}
                        selectedCustomer={selectedCustomer}
                        getCustomerInfo={getCustomerInfo}
                        refreshFunc={getAllCustomersData}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="px-4 py-3 ms-3 bg-white shadow-sm "
            style={{ borderRadius: 10 }}
          >
            <CustomerDetailsColumn
              customerDetails={selectedCustomer}
              refreshFunc={getAllCustomersData}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default CustomerDetails;

import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function Inbox() {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [emailsList, setEmailsList] = useState([]);

  /*   All Functions
   ********************************************* */

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const CheckMails = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/emails/new-emails", {
        headers: authHeader(),
      });
      if (data?.success) {
        dispatch(setNotification({ type: "success", message: data.message }));
        setEmailsList(data.emails);
      } else if (!data.success) {
        dispatch(setNotification({ type: "error", message: data.message }));
      } else {
        dispatch(
          setNotification({
            type: "error",
            message: "Something went wrong CheckLogs",
          })
        );
      }
      console.log(data);

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div
      className={`flex-grow-1   px-5 py-2`}
      style={{
        position: "fixed",
        overflow: "auto",
        top: 0,
        width: "calc(100vw - 200px)",
        marginLeft: 210,
        zIndex: "99",
      }}
    >
      {loading && <Spinner />}
      <div className="row">
        <div className="col-md-12">
          <h2> Inbox </h2>
          <div className="row bg-white shadow-lg px-2 py-3">
            <div className="col-md-2">
              <h4>Actions</h4>
              <hr />
              <div className="d-flex flex-column gap-2">
                <button onClick={CheckMails} className="btn btn-sm btn-primary">
                  Check New Mails
                </button>
                <button className="btn btn-sm btn-info">Mark as Read</button>
              </div>
            </div>
            <div className="col-md-10">
              <h4>Emails</h4>
              <hr />
              <div class="list-group">
                {emailsList.length &&
                  emailsList.map((email) => (
                    <div key={email.seqno} class="list-group-item ">
                      <div className="row mb-2 py-1 border-bottom border-secondary">
                        <div className="col-md-1">From:</div>
                        <div className="col-md-11">
                          <div className="">{email.from}</div>
                        </div>
                      </div>
                      <div className="row mb-2 py-1 border-bottom border-secondary">
                        <div className="col-md-1">Subject:</div>
                        <div className="col-md-11">
                          <div className="">{email.subject}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1">Body:</div>
                        <div className="col-md-11">
                          <div
                            dangerouslySetInnerHTML={{ __html: email.body }}
                            className=""
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

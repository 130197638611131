import TableOnly from "components/DataTable/TableOnly";
import Pagination from "components/Pagination/Pagination";
import SearchComp from "components/SearchComp/SearchComp";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import * as XLSX from "xlsx";
import ViewWOProducts from "./ViewWOProduct";
import WorkOrdersAdd from "./WorkOrdersAdd";

function WorkOrders() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();

  /*   All States
   ********************************************* */
  const [workOrders, setWorkOrders] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showWorkOrderProductModal, setShowWorkOrderProductModal] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const [active, setActive] = useState(null);
  const [limit, setLimit] = useState(0);

  const [locationsList, setLocationsList] = useState([]);
  const [location, setLocation] = useState("");

  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(null);

  /*   All Functions
   ********************************************* */
  const changeLimit = (val) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("limit", val);
      return newParams.toString();
    });
  };

  const changeLocation = (e) => {
    let val = e.target.value;
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("location", val);
      return newParams.toString();
    });
    // if val === "none" remove param location
    if (val === "none") {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("location");
        return newParams.toString();
      });
    }
  };

  function getWOBodyData(searchOptions, activeTab) {
    let page = searchOptions.get("page");
    let pageLimit = searchOptions.get("limit");
    let query = searchOptions.get("query");
    let location = searchOptions.get("location");
    let body = { page, activeTab, pageLimit };

    if (query) {
      body.query = query;
    }
    if (location) {
      body.location = location;
    }
    return body;
  }

  const getWorkOrders = async (activeTab) => {
    setIsLoading(true);

    try {
      let body = getWOBodyData(searchParams, activeTab);

      const { data } = await API.post("/work-orders", body, {
        headers: authHeader(),
      });
      setWorkOrders(data.result);
      setTotalPages(data.pages);
      setIsLoading(false);
      return data.result;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  // Delete WorkOrders
  const deleteWorkOrder = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await API.delete("/work-orders/delete/" + id, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await getWorkOrders(active);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  const downloadExcel = (data) => {
    // const worksheet = XLSX.utils.json_to_sheet(data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, `${active}.xlsx`);
  };

  const getAllLocations = async () => {
    try {
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocationsList(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const TestFunc = async () => {
    try {
      const { data } = await API.get("/work-orders/test-func", {
        headers: authHeader(),
      });
      console.log(data);
      if (data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All useEffects
   ********************************************* */

  useEffect(() => {
    getAllLocations();
  }, []);

  useEffect(() => {
    if (params?.filterBy) {
      setActive(params?.filterBy);
    } else {
      setActive("pending-processing");
    }
  }, [params]);

  useEffect(() => {
    if (active) {
      getWorkOrders(active);
    }
    // location
    let locationId = searchParams.get("location");
    if (locationId) {
      setLocation(locationId);
    } else {
      setLocation("");
    }
    // Limit
    let pageLimit = searchParams.get("limit");
    if (pageLimit) {
      setLimit(pageLimit);
    } else {
      changeLimit(10);
      setLimit(10);
    }
  }, [active, searchParams]);

  /*   Table Functions
   ********************************************* */
  const ActionButtons = () => (
    <div>
      {(user?.permissions?.includes("work_orders_add") ||
        user?.roles === "admin") && (
        <div className="d-flex align-items-center gap-1 text-nowrap">
          <div className="d-flex align-items-center gap-2">
            <h4>Location</h4>
            <select
              onChange={changeLocation}
              className="form-control"
              defaultValue={location}
            >
              <option value="none">None</option>
              {locationsList?.map((item) => (
                <option value={item.id}>{item?.locationName}</option>
              ))}
            </select>
          </div>
          <button
            onClick={() => downloadExcel(workOrders)}
            type="button"
            className="btn btn-primary btn-success"
          >
            To Excel
          </button>
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn btn-primary "
          >
            ADD WorkOrder
          </button>
          {/* <button
            onClick={TestFunc}
            type="button"
            className="btn btn-primary btn-success"
          >
            Test Func
          </button> */}
        </div>
      )}
    </div>
  );

  const table_head = [
    { name: "WO ID", value: "id" },
    { name: "Customer Name", value: "customerName" },
    { name: "Job Name", value: "jobName" },
    { name: "Location", value: "locationId" },
    { name: "Created Date", value: "createdAt" },
    { name: "Due Date", value: "WOShipDate" },
    { name: "WO Status", value: "WOStatus" },
    { name: "Actions", value: "Actions" },
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1 me-0">{item.woId}</td>
      <td className="py-1 me-0">{item.customerName?.slice(0, 30)}</td>
      <td className="py-1 me-0">{item.jobName?.slice(0, 30)}</td>
      <td className="py-1 me-0">{item?.location?.name}</td>
      <td className="py-1 me-0">
        {convertSqlTimestampToDate(item.createdAt)}{" "}
      </td>
      <td className="py-1 me-0">
        {convertSqlTimestampToDate(item.WOShipDate)}{" "}
      </td>
      <td className="py-1 me-0">{item?.WOStatus}</td>
      <td className="table-action py-1 me-0">
        <button
          onClick={() => {
            setSelectedId(item.id);
            setShowWorkOrderProductModal(true);
          }}
          className={`btn btn-sm bg-success text-white`}
        >
          Quick View
        </button>
        <button
          onClick={() => {
            navigate(
              `/work-order/details/${active}/${
                item?.id
              }?${searchParams.toString()}`
            );
          }}
          className={`btn btn-sm bg-success text-white mx-2 `}
        >
          Details
        </button>
        {(window.location.hostname === "localhost" ||
          window.location.hostname === "127.0.0.1") && (
          <button
            onClick={() => {
              deleteWorkOrder(item?.id);
            }}
            className="btn btn-danger"
          >
            <i className="mdi mdi-trash-can"></i>
          </button>
        )}
      </td>
    </tr>
  );

  return (
    <div className="row">
      <Toast />
      {workOrders ? (
        <div className="col-12">
          <div className="d-flex">
            {/*    Table Card
             ********************************************* */}
            <div className=" flex-grow-1">
              <div
                className={`flex-grow-1 d-flex flex-column bg-white shadow-lg px-5 py-2`}
                style={{
                  position: "fixed",
                  top: 0,
                  width: "calc(100vw - 200px)",
                  marginLeft: 200,
                  zIndex: "99",
                }}
              >
                <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1">
                  <li
                    onClick={() => {
                      navigate(
                        "/work-orders/pending-processing?page=1&limit=" +
                          limit +
                          `${location && "&location=" + location}`
                      );
                    }}
                    className="nav-item"
                  >
                    <button
                      className={
                        active === "pending-processing"
                          ? `nav-link rounded-0 active bg-primary text-white`
                          : "nav-link rounded-0"
                      }
                    >
                      <span className="d-none d-md-block">
                        Pending & Processing
                      </span>
                    </button>
                  </li>

                  <li
                    onClick={() => {
                      navigate(
                        "/work-orders/completed?page=1&limit=" +
                          limit +
                          `${location && "&location=" + location}`
                      );
                    }}
                    className="nav-item"
                  >
                    <button
                      className={
                        active === "completed"
                          ? `nav-link rounded-0 active bg-primary text-white`
                          : "nav-link rounded-0"
                      }
                    >
                      <span className="d-none d-md-block">COMPLETED</span>
                    </button>
                  </li>
                  <li
                    onClick={() => {
                      navigate(
                        "/work-orders/all?page=1&limit=" +
                          limit +
                          `${location && "&location=" + location}`
                      );
                    }}
                    className=""
                  >
                    <button
                      className={
                        active === "all"
                          ? `nav-link rounded-0 active bg-primary text-white`
                          : "nav-link rounded-0"
                      }
                    >
                      <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                      <span className="d-none d-md-block">ALL</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="">
                <>
                  <div
                    className=" px-3 py-2 pb-2 horizontal-scrollable py-4 d-flex flex-column flex-grow-1 mt-5"
                    style={{ borderRadius: 10, marginLeft: 260 }}
                  >
                    <div className="d-flex justify-content-end align-items-center mb-3">
                      <div className="flex-grow-1 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-1">
                          <div>Show</div>
                          <button
                            className="px-1 bg-primary bg-opacity-10 border border-primary rounded-end rounded-start "
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {limit}
                          </button>
                          <ul
                            style={{ width: "fit-content" }}
                            className="dropdown-menu"
                          >
                            <li
                              onClick={() => {
                                changeLimit(5);
                              }}
                            >
                              <button className="dropdown-item" href="#">
                                5
                              </button>
                            </li>
                            <li
                              onClick={() => {
                                changeLimit(10);
                              }}
                            >
                              <button className="dropdown-item" href="#">
                                10
                              </button>
                            </li>
                            <li
                              onClick={() => {
                                changeLimit(15);
                              }}
                            >
                              <button className="dropdown-item" href="#">
                                15
                              </button>
                            </li>
                            <li
                              onClick={() => {
                                changeLimit(20);
                              }}
                            >
                              <button className="dropdown-item" href="#">
                                20
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="me-1">{ActionButtons()}</div>
                      </div>
                      <SearchComp
                        activeTab={active}
                        setLoading={setIsLoading}
                        setTotalPages={setTotalPages}
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        apiPath={`/work-orders/${active}`}
                      />
                    </div>
                    {searchResults ? (
                      <TableOnly
                        table_head={table_head}
                        table_body={table_body}
                        table_data={searchResults}
                        totalPages={totalPages}
                      />
                    ) : (
                      <TableOnly
                        table_head={table_head}
                        table_body={table_body}
                        table_data={workOrders}
                        totalPages={totalPages}
                      />
                    )}
                    {isLoading && <Spinner />}
                    {!searchResults && (
                      <Pagination
                        totalPages={totalPages}
                        apiPath={`/work-orders/${active}?limit=${limit}${
                          location && `&location=${location}`
                        }&page=`}
                        currPage={parseInt(searchParams.get("page")) || 1}
                      />
                    )}
                  </div>
                </>
              </div>
            </div>

            {/*   ADD Modal
             ********************************************* */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
              <Modal.Body>
                <div>
                  <WorkOrdersAdd showAddModal={setShowAddModal} />
                </div>
              </Modal.Body>
            </Modal>

            {/*   Update Modal
             ********************************************* */}
            <Modal
              size="xl"
              show={showWorkOrderProductModal}
              onHide={() => {
                setShowWorkOrderProductModal(false);
              }}
            >
              <Modal.Body>
                <div>
                  {active && (
                    <ViewWOProducts
                      refreshFunc={getWorkOrders}
                      active={active}
                      allWOsData={workOrders}
                      setShowWorkOrderProductModal={
                        setShowWorkOrderProductModal
                      }
                      selectedId={selectedId}
                    />
                  )}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default WorkOrders;

import WOPdfButton from "components/Pdfs/WOPdf/WOPdfButton";
import PrintProductPdf from "components/ProductPdfPrintButton/PrintProductPdf";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import { setNotification } from "features/Notification/notificationSlice";
import ImageWIthSignedUrl from "pages/DesignRequests/DesignRequest/ImageWIthSignedUrl";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import authHeader from "services/auth-header";
import API from "services/axios";

function WOProductQuickView({
  selectedWOID,
  allWOData,
  filterBy,
  setShowProductsModal,
  productTypesData,
}) {
  /*   All States
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const [allQuickViewData, setAllQuickViewData] = useState(null);

  const [allSizes, setAllSizes] = useState(null);
  const [generalData, setGeneralData] = useState(null);
  const [productIndex, setProductIndex] = useState(0);
  const [WOProductsData, setWOProductsData] = useState(null);
  const [count, setCount] = useState(1);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [WOIndex, setWOIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isReady, setIsReady] = useState(false);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  useEffect(() => {
    if (allWOData) {
      setAllQuickViewData(allWOData);
      allWOData.map((item, index) => {
        if (item.id === selectedWOID) {
          setWOIndex(index);
        }
      });
    }
  }, [allWOData, selectedWOID]);

  useEffect(() => {
    if (WOIndex !== null && allQuickViewData) {
      let selected = allQuickViewData[WOIndex];
      setSelectedWorkOrder(selected);
    }
  }, [WOIndex, allQuickViewData]);

  useEffect(() => {
    if (selectedWorkOrder) {
      setWOProductsData(selectedWorkOrder?.WOProducts);
      setProductIndex(0);
    }
  }, [selectedWorkOrder]);

  const prevWO = () => {
    if (WOIndex == 0) {
      return;
    } else {
      setWOIndex((prevState) => prevState - 1);
      setCount(1);
      setProductIndex(0);
    }
  };
  const nextWO = () => {
    if (WOIndex == allQuickViewData?.length - 1) {
      return;
    } else {
      setWOIndex((prevState) => prevState + 1);
      setCount(1);
      setProductIndex(0);
    }
  };

  /*   All Function
   ********************************************* */

  const setWorkOrderContext = (data) => {
    // Navigate after state has been set
    let status =
      data?.WOStatus === "pending" || data?.WOStatus === "processing"
        ? "pending-processing"
        : data.WOStatus;
    navigate(`/work-order/details/${status}/${data.id}?page=1`);
  };

  const tempUpdateReadyProduct = (productId, ready) => {
    let newProducts = [...WOProductsData];
    let updatedProducts = [];
    let index = newProducts.findIndex(
      (product) => product.id === parseInt(productId)
    );
    if (index !== -1) {
      newProducts[index].isReady = ready;
      updatedProducts = newProducts;
    }
    let newAllQuickViewData = [...allQuickViewData];
    let selectedIndex = newAllQuickViewData?.findIndex(
      (wo) => wo.id === parseInt(selectedWorkOrder?.id)
    );
    if (selectedIndex !== -1) {
      newAllQuickViewData[selectedIndex].WOProducts = updatedProducts;

      setAllQuickViewData(newAllQuickViewData);
    }
  };

  const UpdateProduct = async ({ ready }) => {
    try {
      setIsLoading(true);
      for (let index = 0; index < WOProductsData.length; index++) {
        const product = WOProductsData[index];
        await API.patch(
          "/wo-products",
          { data: { id: product?.id, isReady: ready } },
          { headers: authHeader() }
        );
        tempUpdateReadyProduct(product?.id, ready);
      }

      // let data = await refreshFunc();

      // if (data.length && data.length > 1) {
      //   // setWOIndex((prev) => prev - 1);
      // } else {
      //   setIsLoading(false);
      //   setShowProductsModal(false);
      // }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleProductTypeSelection = async (e) => {
    let selectedType = e.target.value;

    if (!generalData) {
      return;
    }

    setIsLoading(true);
    let updateData = {
      id: generalData.id,
      productType: selectedType,
    };

    try {
      const { data } = await API.patch(
        "/wo-products",
        { data: updateData },
        {
          headers: authHeader(),
        }
      );
      if (data?.message?.type === "success") {
        setWOProductsData((prev) => {
          prev[productIndex]["productType"] = selectedType;
          return prev;
        });

        // let response = await refreshFunc();
        // if (response.length) {
        //   setWOIndex(response.length - 1);
        //   setIsLoading(false);
        //   return;
        // } else {
        //   setIsLoading(false);
        //   setShowProductsModal(false);
        //   return;
        // }
      }

      if (data?.message?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message?.message,
            type: "error",
          })
        );
        setIsLoading(false);
        return;
      }
      console.log(data);
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  const tempUpdateWOStatus = (status) => {
    let newAllQuickViewData = [...allQuickViewData];
    let selectedIndex = newAllQuickViewData?.findIndex(
      (wo) => wo.id === parseInt(selectedWorkOrder?.id)
    );
    if (selectedIndex !== -1) {
      newAllQuickViewData[selectedIndex].WOStatus = status;
      setAllQuickViewData(newAllQuickViewData);
    }
  };

  const handleWOStatusChange = async (value, id, setIsLoading) => {
    setIsLoading(true);
    try {
      const { data } = await API.patch(
        "/work-orders",
        {
          data: { WOStatus: value },
          id,
        },
        { headers: authHeader() }
      );

      if (data.message.type === "success") {
        setTimeout(async () => {
          setIsLoading(false);
        }, 1000);

        tempUpdateWOStatus(value);
        // let data = await refreshFunc();

        // if (data.length && !WOIndex === 0) {
        //   setWOIndex((prev) => prev - 1);
        //   setIsLoading(false);
        // } else if (data.length && WOIndex === 0) {
        //   setIsLoading(false);
        // } else {
        //   setIsLoading(false);
        //   setShowProductsModal(false);
        // }
      } else {
        dispatch(
          setNotification({
            message: "Something Else",
            type: "error",
          })
        );
      }
    } catch (err) {
      console.log(err.message);

      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  const { clearWorkOrderData, setCustomerData } = useWorkOrder();
  const handleViewCustomer = () => {
    setCustomerData({ id: selectedWorkOrder?.customerId });
    clearWorkOrderData();
    navigate("/customer");
  };

  useEffect(() => {
    if (WOProductsData) {
      let selected = WOProductsData[productIndex];

      setIsReady(selected?.isReady);

      setGeneralData(selected);
      setAllSizes(
        selected?.productSizes
          ? typeof selected?.productSizes === "string"
            ? JSON.parse(selected?.productSizes)
            : selected?.productSizes
          : []
      );
    }
  }, [productIndex, WOProductsData]);

  const prevProduct = () => {
    if (productIndex == 0) {
      return;
    } else {
      setProductIndex((prevState) => prevState - 1);
      setCount((prev) => prev - 1);
    }
  };
  const nextProduct = () => {
    if (productIndex == WOProductsData.length - 1) {
      return;
    } else {
      setProductIndex((prevState) => prevState + 1);
      setCount((prev) => prev + 1);
    }
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <Toast />
      <div className="mb-1 mx-1 me-2 d-flex justify-content-between">
        <div>
          <h4>
            {(user?.permissions?.includes("work-orders_add") ||
              user?.permissions?.includes("work-orders_update") ||
              user?.permissions?.includes("work-orders_delete") ||
              user?.permissions?.includes("work-orders_dataEntry") ||
              user?.roles === "admin") && (
              <button
                onClick={() => {
                  setWorkOrderContext(selectedWorkOrder);
                }}
                className={`btn btn-sm py-0 bg-info text-white mx-1 my-0 `}
              >
                View Work Order
              </button>
            )}
            <span>
              {selectedWorkOrder?.woId} -{" "}
              {selectedWorkOrder?.jobName?.length <= 20
                ? selectedWorkOrder?.jobName
                : `${selectedWorkOrder?.jobName?.slice(0, 20)}..`}
            </span>
          </h4>
          <h4>
            {(user?.permissions?.includes("customer_add") ||
              user?.permissions?.includes("customer_update") ||
              user?.permissions?.includes("customer_delete") ||
              user?.roles === "admin") && (
              <button
                onClick={handleViewCustomer}
                className={`btn btn-sm py-0 bg-success text-white mx-1 my-0 `}
              >
                View Customer
              </button>
            )}

            <span>
              {selectedWorkOrder?.customer?.customerId} -{" "}
              {selectedWorkOrder?.customerName?.length <= 20
                ? selectedWorkOrder?.customerName
                : `${selectedWorkOrder?.customerName?.slice(0, 20)}..`}
            </span>
          </h4>
        </div>
        <div className="d-flex justify-content-end align-items-center flex-grow-1">
          {generalData && (
            <>
              <div className="d-flex align-items-center gap-2 me-2">
                <label className="col-5">Ready?</label>
                <div className="col-7 ">
                  <input
                    onChange={(e) => {
                      setIsReady(e.target.checked);
                      UpdateProduct({
                        id: generalData?.id,
                        ready: e.target.checked,
                      });
                    }}
                    checked={isReady}
                    type="checkbox"
                    id="switch1"
                    data-switch="success"
                  />
                  <label
                    htmlFor="switch1"
                    data-on-label="Yes"
                    data-off-label="No"
                  ></label>
                </div>
              </div>
            </>
          )}

          <h5 className="me-1">WO Status</h5>
          <div className="me-1 ">
            <select
              value={selectedWorkOrder?.WOStatus || ""}
              onChange={(e) => {
                handleWOStatusChange(
                  e.target.value,
                  selectedWorkOrder?.id,
                  setIsLoading
                );
              }}
              className="form-select"
            >
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div className="d-flex gap-1">
            <>
              {/* <PrintProductPdf
                btnClass={"btn btn-success ms-1"}
                btnTitle={"Print Pdf"}
                filterBy={filterBy}
                selectedWorkOrder={selectedWorkOrder}
                WOProductsData={WOProductsData}
              /> */}
              <WOPdfButton
                btnClasses={"btn btn-success ms-1"}
                btnTitle={"Print Pdf"}
                selectedWO={selectedWorkOrder}
                filterBy={filterBy}
              />
            </>
            <button
              onClick={() => {
                prevWO();
              }}
              disabled={allQuickViewData?.length === 0 || isLoading}
              className={"btn btn-success btn-sm p-0 px-1 "}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextWO();
              }}
              disabled={allQuickViewData?.length === 0 || isLoading}
              className={"btn btn-success btn-sm p-0  px-1"}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>

            <button
              className="btn btn-danger"
              onClick={() => {
                setShowProductsModal(false);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <hr className="mb-0" />
      <div className="d-flex">
        {/*   Left Panel
         ********************************************* */}
        <div
          className=" bg-white shadow p-2 w-50  mb-2 me-2"
          style={{ borderRadius: 15 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h3>
              Product
              {` {${WOProductsData?.length === 0 ? " 0 " : ` ${count} `} of ${
                WOProductsData?.length
              } }`}
            </h3>
            <div className="d-flex align-items-center gap-1">
              {/*   WO Product Buttons
               ********************************************* */}

              <button
                onClick={() => {
                  prevProduct();
                }}
                disabled={WOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1 "}
              >
                <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
              </button>
              <button
                onClick={() => {
                  nextProduct();
                }}
                disabled={WOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1"}
              >
                <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
              </button>
            </div>
          </div>
          <div className=" p-1">
            <div className="row mb-1">
              <label className="col-5">Product Type</label>
              {/* <div className="col-7 d-flex align-items-center">
                {generalData?.productType}
              </div> */}
              <select
                disabled={!generalData}
                value={generalData?.productType ? generalData.productType : ""}
                className="col-7 w-50 form-control w-fit"
                onChange={handleProductTypeSelection}
              >
                {productTypesData &&
                  productTypesData?.map((item) => (
                    <option key={item?.id} value={item?.productTypes}>
                      {item?.productTypes}
                    </option>
                  ))}
              </select>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Style</label>
              <div className="col-7 d-flex align-items-center">
                {generalData?.productStyle}
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Description</label>
              <div className="col-7 ">{generalData?.productDescription}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Fabric</label>
              <div className="col-7 ">{generalData?.productFabric}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Color</label>
              <div className="col-7 ">{generalData?.productColor}</div>
            </div>
          </div>
          <div className="row mb-1">
            <label className="col-5">Team Name</label>
            <div className="col-7 ">{generalData?.teamName}</div>
          </div>

          <div className="row">
            <div className=" col-5 d-flex mb-1">
              <label className="text-nowrap ">Has Names</label>
              <div className="d-flex form-switch " style={{ paddingLeft: 48 }}>
                <input
                  type="checkbox"
                  checked={generalData?.hasName ? true : false}
                  readOnly
                  className="form-check-input "
                  style={{ width: 40 }}
                />
              </div>
            </div>
            <div className="col-7 d-flex mb-1">
              <label className="text-nowrap">Has Numbers</label>
              <div className="d-flex form-switch " style={{ paddingLeft: 48 }}>
                <input
                  type="checkbox"
                  checked={generalData?.hasNumbers ? true : false}
                  readOnly
                  className="form-check-input "
                  style={{ width: 40 }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*   Right Panel
         ********************************************* */}
        <div className="w-50 flex-grow-1 pb-2 d-flex flex-column">
          <div
            className="bg-white shadow px-3 mb-2 me-2 d-flex flex-column py-2"
            style={{ borderRadius: 15, height: 250 }}
          >
            <div className="" style={{ overflowY: "scroll", height: "99%" }}>
              <div className="table-responsive  ">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">Size</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSizes?.map((item, i) => (
                      <tr key={i}>
                        <td className="py-0">{item?.size?.toUpperCase()}</td>
                        <td className="py-0">{item?.quantity}</td>
                        <td className="py-0">
                          $ {parseFloat(item?.price).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="d-flex justify-content-evenly flex-grow-1 py-1 align-items-center bg-white shadow me-2"
              style={{ borderRadius: 15 }}
            >
              <div>
                Total Qty
                <span className="border ms-1 px-2">
                  {parseFloat(generalData?.totalQuantity)}
                </span>
              </div>
              <div>
                Total Price
                <span className="border ms-1 px-2">
                  $ {parseFloat(generalData?.totalPrice).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Pane */}
      <div className="d-flex">
        {/*   Image select
         ********************************************* */}

        <div
          className="bg-white shadow p-2 w-50 mb-2 me-2 flex-center"
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          {!isLoading ? (
            <div>
              {generalData?.productImage ? (
                <ImageWIthSignedUrl
                  imgStyle={{
                    width: 500,
                    height: 300,
                  }}
                  imgClasses={"mb-2"}
                  path={generalData?.productImage}
                  alt="product image"
                />
              ) : (
                <div className="fs-3 text-muted  text-center my-auto">
                  NO Image{" "}
                </div>
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        {/*   Notes
         ********************************************* */}
        <div
          className="bg-white shadow p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          <div className="">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={generalData?.notes}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WOProductQuickView;

import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function SelectedShipAdd({
  table_head,
  selectedCustomer,
  refreshFunc,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);

  const [showShipModal, setShowShipModal] = useState(null);

  /*   All Functions
   ********************************************* */

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const updateSelectedShippingAddress = async (e) => {
    e.preventDefault();

    try {
      loadingOn();
      const { data } = await API.patch(
        "/customer/ship-address",
        {
          id: selectedCustomer?.id,
          shippingAddress: JSON.stringify(shippingAddress),
        },
        {
          headers: authHeader(),
        }
      );

      if (data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        await refreshFunc();
        setShowShipModal(false);
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }
    let shipAddress = selectedCustomer?.shippingAddress
      ? selectedCustomer?.shippingAddress
      : {};
    let _shippingAddress =
      typeof shipAddress === "string" ? JSON.parse(shipAddress) : shipAddress;
    setShippingAddress({
      description: _shippingAddress?.description,
      addressCompany: _shippingAddress?.addressCompany,
      addressContact: _shippingAddress?.addressContact,
      addressLine1: _shippingAddress?.addressLine1,
      addressLine2: _shippingAddress?.addressLine2,
      addressCity: _shippingAddress?.addressCity,
      addressState: _shippingAddress?.addressState,
      addressZipCode: _shippingAddress?.addressZipCode,
      addressCountry: _shippingAddress?.addressCountry,
    });
  }, [selectedCustomer]);
  return (
    <div>
      <div className="d-flex">
        <h4 className="border-secondary border-bottom">
          SELECTED SHIPPING ADDRESS
        </h4>
      </div>

      <div>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    {table_head.map((head, index) => (
                      <th key={index}>{head}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-1 align-middle">
                      {shippingAddress?.description}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressCompany}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressContact}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressLine1}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressLine2}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressCity}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressState}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressZipCode}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressCountry}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      <button
                        onClick={(e) => {
                          setShowShipModal(true);
                        }}
                        className="btn btn-sm btn-info me-1"
                      >
                        <i className="mdi mdi-pencil"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showShipModal} onHide={() => setShowShipModal(false)}>
        <Modal.Body>
          {loading && <Spinner />}
          {/*   Shipping Address
           ********************************************* */}
          <form onSubmit={updateSelectedShippingAddress}>
            <div>
              <div>
                <div className="">
                  <h4 className="mb-0">Selected Shipping Address</h4>
                </div>
                <hr className="mt-0" />
                <div className="row mb-1">
                  <label className="col-3 my-auto">Description</label>
                  <div className="col-9 ">
                    <input
                      type="text"
                      value={shippingAddress?.description || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          description: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-3 my-auto">Attention to</label>
                  <div className="col-9 ">
                    <input
                      type="text"
                      value={shippingAddress?.addressContact || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressContact: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter attention to"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-3 my-auto">Company Name</label>
                  <div className="col-9 ">
                    <input
                      type="text"
                      value={shippingAddress?.addressCompany || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressCompany: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter comapny name"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <label className="col-3 my-auto">Adddress 1</label>
                  <div className="col-9 ">
                    <input
                      type="text"
                      value={shippingAddress?.addressLine1 || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressLine1: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter adddress 1"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-3 my-auto">Adddress 2</label>
                  <div className="col-9 ">
                    <input
                      type="text"
                      value={shippingAddress?.addressLine2 || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressLine2: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter adddress 2"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <label className="col-3 my-auto">City/State/Zipcode</label>
                  <div className="col-9 ">
                    <div className="d-flex">
                      <input
                        type="text"
                        value={shippingAddress?.addressCity || ""}
                        onChange={(e) =>
                          setShippingAddress({
                            ...shippingAddress,
                            addressCity: e.target.value,
                          })
                        }
                        className="form-control w-50"
                        placeholder="City"
                      />
                      <input
                        type="text"
                        value={shippingAddress?.addressState || ""}
                        onChange={(e) =>
                          setShippingAddress({
                            ...shippingAddress,
                            addressState: e.target.value,
                          })
                        }
                        style={{ width: "20%" }}
                        className="form-control"
                        placeholder="State"
                      />
                      <input
                        type="number"
                        value={shippingAddress?.addressZipCode || ""}
                        onChange={(e) =>
                          setShippingAddress({
                            ...shippingAddress,
                            addressZipCode: e.target.value,
                          })
                        }
                        style={{ width: "30%" }}
                        className="form-control"
                        placeholder="Zipcode"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-3 my-auto">Country</label>
                  <div className="col-9 ">
                    <input
                      type="text"
                      value={shippingAddress?.addressCountry || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressCountry: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter country"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*   Buttons
             ********************************************* */}
            <div className="mt-3 flex-grow-1 d-flex justify-content-end align-items-end">
              <div>
                <button
                  onClick={() => setShowShipModal(false)}
                  type="button"
                  className="btn btn-secondary me-2 px-1"
                >
                  CLOSE
                </button>
                <button type="submit" className="btn btn-primary">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import logo from "../../../assets/images/logo.png";
import EmailToCustomer from "./EmailToCustomer";
import MockupDetails from "./Mockups/MockupDetails";
import UpdateCustomer from "./UpdateCustomer";

export default function DesignRequestLayout({
  selectedDesign,
  refreshFunc,
  setSelectedDesign,
  designRequests,
  setDesignRequests,
  setShowDetailsPage,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();
  const companyLogo = useSelector(
    (state) => state?.company?.company?.companyLogo
  );

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState(null);
  const [showCopyLinkModal, setShowCopyLinkModal] = useState(false);

  /*   All Functions
   ********************************************* */
  const getLink = async ({ showMsg }) => {
    try {
      const { data } = await API.post(
        "/art-approval/get-link",
        { selectedDesign },
        { headers: authHeader() }
      );
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_URI}/mockups/${data.link}`
      );
      setLink(`${process.env.REACT_APP_URI}/mockups/${data.link}`);
      if (showMsg) {
        dispatch(
          setNotification({
            message: "Link Copied to clipboard",
            type: "success",
          })
        );
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pt-2">
        <div className="flex-grow-1">
          <div className=" custom-border-radius d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3 align-items-start ">
              {!isLoading && (
                <div style={{ width: 150, height: 100 }} className="">
                  {selectedDesign?.customer?.customerLogo ? (
                    <ImgWithSignedUrl
                      path={selectedDesign?.customer?.customerLogo}
                      styles={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : selectedDesign?.customer?.location?.logo ? (
                    <img
                      src={selectedDesign?.customer?.location?.logo}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "",
                      }}
                      alt="location logo"
                    />
                  ) : companyLogo ? (
                    <ImgWithSignedUrl
                      styles={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      path={companyLogo}
                    />
                  ) : (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "",
                      }}
                    />
                  )}
                </div>
              )}
              <div className="d-flex flex-column justify-content-start align-items-start mt-2">
                <h3 className="mt-0">{selectedDesign?.title}</h3>
                <h4 className="mt-0">
                  {selectedDesign?.customer?.customerName} -{" "}
                  {selectedDesign?.customer?.location?.locationName} -
                  <small>
                    ( {selectedDesign?.contactName} - {selectedDesign?.email} -{" "}
                    {selectedDesign?.phone} )
                  </small>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-1">
          <div className="">
            <button
              type="button"
              onClick={() => {
                setShowUpdateModal(true);
              }}
              className="btn btn-success
            "
            >
              Change Name & Customer
            </button>
            <button
              type="button"
              onClick={() => {
                setShowCopyLinkModal(true);
              }}
              className="btn btn-info ms-1"
            >
              Email
            </button>
          </div>
          <div className="">
            <button
              type="button"
              onClick={() => {
                getLink({ showMsg: true });
              }}
              className="btn btn-info
            "
            >
              Guest Link
            </button>
          </div>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowDetailsPage(false);
              setSelectedDesign(null);
              setDesignRequests(null);
            }}
          >
            Back
          </button>
        </div>
      </div>
      <div>
        <MockupDetails
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          designRequests={designRequests}
          setDesignRequests={setDesignRequests}
        />
      </div>

      {/*   Edit Project Name and Change Customer
       ********************************************* */}
      <Modal
        show={showUpdateModal}
        contentClassName="bg-transparent"
        onHide={() => setShowUpdateModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <UpdateCustomer
            setIsLoading={setIsLoading}
            refreshFunc={refreshFunc}
            showModal={setShowUpdateModal}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
          />
        </Modal.Body>
      </Modal>

      {/*   Email To Customer
       ********************************************* */}
      <Modal
        show={showCopyLinkModal}
        contentClassName="bg-transparent"
        onHide={() => {
          setShowCopyLinkModal(false);
        }}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <EmailToCustomer
            link={link}
            getLink={getLink}
            showModal={setShowCopyLinkModal}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

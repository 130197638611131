import API from "services/axios";
import authHeader from "services/auth-header";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";

function CustomerUpdate({
  id,
  selected,
  refreshFunc,
  addressData,
  setShowUpdateModal,
}) {
  const { register, handleSubmit } = useForm();

  /*   All States
   ********************************************* */

  const [checkMarks, setCheckMarks] = useState({
    signatureRelease: false,
    selfCollect: false,
  });

  /*   All Functions
   ********************************************* */

  // Add Customers

  const updateCustomer = async (formData, e) => {
    e.preventDefault();

    const submitData = {
      id: selected.id,
      sortId: selected.sortId,
      type: "address",
      customerId: id,
      data: JSON.stringify(formData),
    };

    try {
      const { data } = await API.patch(
        "/customers/addresses/update",
        { data: submitData },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
        setShowUpdateModal(false);
        return;
      }
      alert(data);
    } catch (err) {
      alert(err.message);
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    setCheckMarks({
      signatureRelease: selected.signatureRelease,
      selfCollect: selected.selfCollect,
    });
  }, [selected]);

  return (
    <div className="d-flex justify-content-center flex-column px-2">
      <h3 className="mb-3"> UPDATE ADDRESS </h3>
      <form onSubmit={handleSubmit(updateCustomer)}>
        <div className="row">
          <div className="col-6">
            {/* Description */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Description</label>
              </div>
              <div className="col-8">
                <input
                  {...register("description")}
                  defaultValue={selected?.description}
                  placeholder="Enter description"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Address Company Name */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Company Name</label>
              </div>
              <div className="col-8">
                <input
                  {...register("addressCompanyName")}
                  defaultValue={selected?.addressCompanyName}
                  placeholder="Enter address company name"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Attention To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Contact Name</label>
              </div>
              <div className="col-8">
                <input
                  {...register("attentionTo")}
                  defaultValue={selected?.attentionTo}
                  placeholder="Enter attention to"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            {/* Attention To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Phone</label>
              </div>
              <div className="col-8">
                <input
                  {...register("phone")}
                  defaultValue={selected?.phone}
                  placeholder="Enter phone"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            {/* Attention To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Email</label>
              </div>
              <div className="col-8">
                <input
                  {...register("email")}
                  defaultValue={selected?.email}
                  placeholder="Enter attention to"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <hr />

            {/*   DHL Section
             ********************************************* */}

            {/* Remote Shipping */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Remote Address</label>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-between gap-2x">
                <div className="d-flex me-2 ">
                  <input
                    type="text"
                    className="form-control "
                    disabled
                    value={"Set from Api"}
                  />
                </div>
                <div className="">
                  <button type="button" className="btn text-nowrap btn-info">
                    Validate Address
                  </button>
                </div>
              </div>
            </div>
            {/* Signature Release  */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Signature Release</label>
              </div>
              <div className="col-7 d-flex">
                <input
                  type="checkbox"
                  checked={checkMarks?.signatureRelease}
                  onChange={(e) => {
                    setCheckMarks({
                      selfCollect: !e.target.checked,
                      signatureRelease: e.target.checked,
                    });
                  }}
                  id={"signatureRelease"}
                  data-switch="success"
                />
                <label
                  htmlFor={"signatureRelease"}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
            {checkMarks.signatureRelease && (
              <div>
                {/* Authorize Person */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Authorize Person</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      {...register("authorizePerson")}
                      defaultValue={selected?.authorizePerson}
                      placeholder="Enter Authorize Person"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Authorize Location */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Authorize Location</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      {...register("authorizeLocation")}
                      defaultValue={selected?.authorizeLocation}
                      placeholder="Enter Authorize location"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Self Collect  */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Self Collect</label>
              </div>
              <div className="col-7 d-flex">
                <input
                  type="checkbox"
                  checked={checkMarks?.selfCollect}
                  onChange={(e) => {
                    setCheckMarks({
                      signatureRelease: !e.target.checked,
                      selfCollect: e.target.checked,
                    });
                  }}
                  id={"selfCollect"}
                  data-switch="success"
                />
                <label
                  htmlFor={"selfCollect"}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
            {/* Service ID */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Service ID</label>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-between gap-2">
                <input
                  {...register("serviceId")}
                  defaultValue={selected?.serviceId}
                  placeholder="Enter Service ID"
                  type="text"
                  className="form-control"
                />
                <button type="button" className="btn btn-info text-nowrap">
                  Service Point
                </button>
              </div>
            </div>
          </div>

          {/*   Right Panel
           ********************************************* */}
          <div className="col-6 d-flex flex-column">
            {/* IS company Location */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Drop Shipping</label>
              </div>
              <div className="col-8">
                <div className="d-flex gap-2 mb-1">
                  <input
                    type="checkbox"
                    {...register("dropShipping")}
                    defaultChecked={selected?.dropShipping}
                    id={"dropShipping"}
                    data-switch="success"
                  />
                  <label
                    htmlFor={"dropShipping"}
                    data-on-label="Yes"
                    data-off-label="No"
                  ></label>
                </div>
              </div>
            </div>
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Address 1</label>
              </div>
              <div className="col-8">
                <input
                  {...register("address1")}
                  defaultValue={selected?.address1}
                  placeholder="Enter address 1"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Address 2 */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Address 2</label>
              </div>
              <div className="col-8">
                <input
                  {...register("address2")}
                  defaultValue={selected?.address2}
                  placeholder="Enter address 2"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Address 2 */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">City | State | Zip</label>
              </div>
              <div className="d-flex col-8">
                <input
                  {...register("city")}
                  defaultValue={selected?.city}
                  placeholder="city"
                  type="text"
                  className="form-control"
                />
                <input
                  {...register("state")}
                  defaultValue={selected?.state}
                  placeholder="state"
                  type="text"
                  className="form-control"
                />
                <input
                  {...register("zipcode")}
                  defaultValue={selected?.zipcode}
                  placeholder="zipcode"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Country</label>
              </div>
              <div className="col-8">
                <input
                  {...register("country")}
                  defaultValue={selected?.country}
                  placeholder="Enter country"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className="mb-1 flex-grow-1 d-flex justify-content-end align-items-end">
            <div>
              <button
                onClick={() => setShowUpdateModal(false)}
                type="button"
                className="btn btn-secondary me-1"
              >
                CLOSE
              </button>
              <button type="submit" className="btn btn-primary">
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CustomerUpdate;

import Spinner from "components/Spinner";
import React, { useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

function LocationAdd({ showAddPage, refreshFunc, lastSortId }) {
  const user = JSON.parse(localStorage.getItem("user"));
  /*   All States Below
   ********************************************* */
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState({});

  /*   All Functions
   ********************************************* */
  const handleFileChange = (e) => {
    let file = e.target.files[0];

    let path = {
      panelLogo: `kms/companyLogos/${user?.companyId}/locationLogo`,
      pdfLogo: `kms/companyLogos/${user?.companyId}/locationLogo/pdfLogo`,
    };

    let fileData = {
      file: file,
      fileName: file.name,
      folder: path[e.target.name],
    };

    setFiles((prev) => {
      return { ...prev, [e.target.name]: fileData };
    });
  };

  const addLocationData = async (e) => {
    setFormError({});
    e.preventDefault();
    setLoading(true);
    if (formData.locationName === "") {
      setFormError({
        locationName: {
          type: "locationName",
          message: "Location Name is required",
        },
      });
      return;
    }

    const formDataValue = new FormData();

    const updatedData = { ...formData, sortId: lastSortId + 1 };
    formDataValue.append("updatedData", JSON.stringify(updatedData));

    Array.from(Object.keys(files)).map((key) => {
      formDataValue.append(`file`, files[key].file);
    });
    formDataValue.append("filesData", JSON.stringify(files));

    try {
      const { data } = await API.post("/locations", formDataValue, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      if (data.message.type === "success") {
        refreshFunc();
        setLoading(false);
        showAddPage(false);
        return;
      }
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  };

  const updateInput = (e) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-3">
          <h3>ADD Location</h3>
        </div>
        <form className="ps-3 pe-3" onSubmit={addLocationData}>
          {/* ----------  Fields  ----------- */}
          <div className="mt-3 mb-3">
            <label htmlFor="title" className="form-label">
              Location Name
            </label>
            <input
              className="form-control"
              type="text"
              id="locationName"
              placeholder="Enter title"
              name="locationName"
              onChange={updateInput}
            />
            {formError && formError.locationName && (
              <p className="text-danger">{formError.locationName.message}</p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="companyName" className="form-label">
              Company Name
            </label>
            <input
              className="form-control"
              type="text"
              id="companyName"
              placeholder="Enter company name"
              name="companyName"
              onChange={updateInput}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="logo" className="form-label">
              Pdf Logo ( W x H : 200px x 100px )
            </label>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-grow-1">
                <input
                  className="form-control"
                  type="file"
                  id="pdfLogo"
                  name="pdfLogo"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          {/* Email Smtp */}
          <hr />
          <div className="mb-3 mt-3">
            <h3 className="">Add email account ( Gmail ) </h3>
            <a
              target="_blank"
              rel="noreferrer"
              referrerPolicy="no-referrer"
              href="https://myaccount.google.com/apppasswords?continue=https://myaccount.google.com/data-and-privacy?hl%3Den%26rapt%3DAEjHL4N-_-wqowUOflRj8zsWBHL93KyUfugluv0j26WMDbVjZEyajo8p5u5lV5u92pylN3k8u3XYEaG_8sR-5AvtsqDp5s1ezPxGXSrsfZ0TRSsAWLzqC7o%26utm_source%3DOGB%26utm_medium%3Dact%26gar%3DWzI0Nl0&pli=1&rapt=AEjHL4OYovdomiZLn4-l-Jfd38P2Dm1untWoxDqkS96MKXuQQHJMrU3kcGmmLc4mH9-qGBpfHarlQUPd1ozfKg9MJQvez1jIhWXqvO-VQaloqWJLsQPzQqw"
            >
              Click here to get your gmail smtp password
            </a>
          </div>
          <div className="mb-3">
            <label htmlFor="smtpName" className="form-label">
              Smtp Name
            </label>
            <input
              className="form-control"
              type="text"
              id="smtpName"
              placeholder="Enter smtp name"
              name="smtpName"
              onChange={updateInput}
            />
          </div>{" "}
          <div className="mb-3">
            <label htmlFor="smtpEmail" className="form-label">
              Smtp email
            </label>
            <input
              className="form-control"
              type="text"
              id="smtpEmail"
              placeholder="Enter smtp email"
              name="smtpEmail"
              onChange={updateInput}
            />
          </div>{" "}
          <div className="mb-3">
            <label htmlFor="smtpPassword" className="form-label">
              Smtp password
            </label>
            <input
              className="form-control"
              type="password"
              id="smtpPassword"
              placeholder="Enter smtp password"
              name="smtpPassword"
              onChange={updateInput}
            />
          </div>
          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-primary ms-1" type="submit">
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default LocationAdd;

import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

export default function EmailSection({
  selectedDesign,
  selectedDesignEmails,
  CheckMails,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      <div
        className="d-flex flex-column justify-content-start scrollbar-hide"
        style={{ height: "75vh", overflowY: "auto" }}
      >
        <button
          onClick={CheckMails}
          className="btn btn-success mb-2 custom-border-radius-sm"
        >
          Refresh
        </button>
        {selectedDesignEmails.length === 0 ? (
          <div className="alert alert-info" role="alert">
            No emails
          </div>
        ) : (
          <Accordion defaultActiveKey={0}>
            {selectedDesignEmails.map((email, i) => (
              <Accordion.Item key={email.uid} eventKey={i}>
                <Accordion.Header>
                  <div>
                    <h5 className="">{email.subject.split(">")[1]}</h5>
                    <h6 className="text-muted">{email.from}</h6>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="">{new Date(email.date).toLocaleString()}</p>
                  <div dangerouslySetInnerHTML={{ __html: email.body }} />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
}

import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function SelectEmailTemplate({
  selectedWorkOrder,
  setSubject,
  setEmailBody,
  showModal,
}) {
  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [emailTemplates, setEmailTemplates] = useState(null);

  /*   All Functions
   ********************************************* */

  const getEmailTemplates = async () => {
    try {
      const { data } = await API.get("/emailformat", {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        setEmailTemplates(data.data);
        return;
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
        return;
      }
      dispatch(
        setNotification({
          message: "Something Went wrong check console.",
          type: "error",
        })
      );
      console.log(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.response.data.message,
          type: "error",
        })
      );
    }
  };

  const getProductDesc = (product) => {
    let sizeList = JSON.parse(product?.productSizes);
    let possibleSizes = [
      "xs",
      "axs",
      "yxs",
      "s",
      "as",
      "ys",
      "as",
      "ys",
      "m",
      "am",
      "ym",
      "l",
      "al",
      "yl",
      "xl",
      "axl",
      "yxl",
      "2xl",
      "a2xl",
      "y2xl",
    ];

    let groupedByPrice = {};
    sizeList?.map((size) => {
      let old = groupedByPrice[size.price]
        ? [...groupedByPrice[size.price]]
        : [];
      old.push(size);
      groupedByPrice[size.price] = old;
    });

    let allProd = [];
    Array.from(Object.keys(groupedByPrice))?.map((price) => {
      // const sortedArr = groupedByPrice[price];
      const sortedArr = groupedByPrice[price]?.sort((a, b) => {
        return possibleSizes.indexOf(b.size) - possibleSizes.indexOf(a.size);
      });

      let totalQty = 0;

      sortedArr.map((item) => {
        totalQty += parseInt(item?.quantity);
      });

      let updatedSize =
        sortedArr.length > 1
          ? `${sortedArr[0]?.size?.toUpperCase()}-${sortedArr[
              sortedArr.length - 1
            ]?.size?.toUpperCase()}`
          : sortedArr[0]?.size?.toUpperCase();

      let updatedDescription = `${product?.productDescription} ${updatedSize}`;

      let newItem = {
        size: updatedSize,
        price: parseFloat(sortedArr[0]?.price),
        productDescription: updatedDescription,
        productStyle: product?.productStyle,
        productColor: product?.productColor,
        productType: product?.productType,
        productFabric: product?.productFabric,
        quantity: totalQty,
        id: product.id,
        sortId: sortedArr[0].sortId,
      };
      allProd.push(newItem);
    });

    const sortedProds = allProd?.sort((a, b) => {
      return a.sortId - b.sortId;
    });

    return sortedProds;
  };

  const getTotalPrice = () => {
    let updatedProductList = [];
    let products = selectedWorkOrder?.WOProducts;

    products?.map((item) => {
      updatedProductList = [...updatedProductList, ...getProductDesc(item)];
    });
    let TotalQty = updatedProductList?.reduce((prev, curr) => {
      let price = parseFloat(
        (parseFloat(curr?.quantity) * parseFloat(curr?.price) * 100) / 100
      ).toFixed(2);
      let total = parseFloat(prev) + parseFloat(price);

      return total;
    }, 0);

    let taxRate = selectedWorkOrder?.taxrate?.rate
      ? selectedWorkOrder?.taxrate?.rate
      : 0;

    let total = TotalQty * taxRate + TotalQty;
    let parsedTotal = ((parseFloat(total) * 100) / 100).toFixed(2);

    return parsedTotal;
  };

  const getSizeAndColorsFabricsList = () => {
    let updatedProductList = [];
    let products = selectedWorkOrder?.WOProducts;

    products?.map((item) => {
      updatedProductList = [...updatedProductList, ...getProductDesc(item)];
    });

    let { sizeList, colorsList, fabricsList } = updatedProductList?.reduce(
      (prev, curr) => {
        let sizeList;
        if (prev.sizeList.includes(curr?.size)) {
          sizeList = prev.sizeList;
        } else {
          sizeList = [
            ...(prev.sizeList || []),
            ...(curr?.size ? [curr.size] : []),
          ];
        }

        let colorsList;
        if (prev.colorsList.includes(curr?.productColor)) {
          colorsList = prev.colorsList;
        } else {
          colorsList = [
            ...(prev.colorsList || []),
            ...(curr?.productColor ? [curr.productColor] : []),
          ];
        }

        let fabricsList;
        if (prev.fabricsList.includes(curr?.productFabric)) {
          fabricsList = prev.fabricsList;
        } else {
          fabricsList = [
            ...(prev.fabricsList || []),
            ...(curr?.productFabric ? [curr.productFabric] : []),
          ];
        }

        return { sizeList, colorsList, fabricsList };
      },
      { sizeList: [], colorsList: [], fabricsList: [] }
    );

    return { sizeList, colorsList, fabricsList };
  };

  const getAddress = (address) => {
    address = typeof address === "string" ? JSON.parse(address) : address;
    return `Attn: 
    ${
      selectedWorkOrder?.customerName
        ? selectedWorkOrder?.customerName + "<br />"
        : ""
    }
    
    ${address?.addressCompany ? address?.addressCompany + "<br />" : ""}
 
    ${address?.addressLine1 ? address?.addressLine1 + "<br />" : ""}
  
    ${address?.addressLine2 ? address?.addressLine2 + "<br />" : ""}
  
    ${address?.city ? address?.city + "," : ""} ${
      address?.state ? address?.state + "," : ""
    } ${address?.zip ? address?.zip + "<br />" : ""}
  
    ${address?.country ? address?.country : ""}`;
  };

  const replaceTemplateVariables = (val) => {
    let updateQuotationId = val.replaceAll(
      "&lt;&lt; ID &gt;&gt;",
      selectedWorkOrder?.id
    );

    let updateCustomerId = updateQuotationId.replaceAll(
      "&lt;&lt; Customer ID &gt;&gt;",
      selectedWorkOrder?.customerId
    );
    let updateCustomerName = updateCustomerId.replaceAll(
      "&lt;&lt; Customer Name &gt;&gt;",
      selectedWorkOrder?.customerName
    );

    let getContactName = (obj) => {
      return `${obj?.firstName} ${obj?.lastName}`;
    };

    let contactName = selectedWorkOrder?.customerName
      ? typeof selectedWorkOrder?.contactData === "string"
        ? getContactName(JSON.parse(selectedWorkOrder?.contactData))
        : getContactName(selectedWorkOrder?.contactData)
      : "NO Contact Name";

    let updateContactName = updateCustomerName.replaceAll(
      "&lt;&lt; Contact Name &gt;&gt;",
      contactName
    );

    let totalPrice = getTotalPrice();

    let updateTotalPrice = updateContactName.replaceAll(
      "&lt;&lt; Invoice Total &gt;&gt;",
      `$ ${totalPrice}`
    );

    let { sizeList, colorsList, fabricsList } = getSizeAndColorsFabricsList();

    let updatedSizeList = updateTotalPrice.replaceAll(
      "&lt;&lt; Sizes List &gt;&gt;",
      sizeList.join(", ")
    );

    let updatedColorList = updatedSizeList.replaceAll(
      "&lt;&lt; Colors List &gt;&gt;",
      colorsList.join(", ")
    );

    let updateBillingAddress = updatedColorList.replaceAll(
      "&lt;&lt; Billing Address &gt;&gt;",
      getAddress(selectedWorkOrder?.billingAddress)
    );

    let updateShippingAddress = updateBillingAddress.replaceAll(
      "&lt;&lt; Shipping Address &gt;&gt;",
      getAddress(selectedWorkOrder?.shippingAddress)
    );

    let updatedFabricsList = updateShippingAddress.replaceAll(
      "&lt;&lt; Fabrics List &gt;&gt;",
      fabricsList.join(", ")
    );

    return updatedFabricsList;
  };

  const replaceSubjectVariables = (val) => {
    let updateQuotationId = val.replaceAll("<< ID >>", selectedWorkOrder?.id);

    let updateCustomerId = updateQuotationId.replaceAll(
      "<< Customer ID >>",
      selectedWorkOrder?.customerId
    );
    let updateCustomerName = updateCustomerId.replaceAll(
      "<< Customer Name >>",
      selectedWorkOrder?.customerName
    );
    let getContactName = (obj) => {
      return `${obj?.firstName} ${obj?.lastName}`;
    };

    let contactName =
      typeof selectedWorkOrder?.contactData === "string"
        ? getContactName(JSON.parse(selectedWorkOrder?.contactData))
        : "NO Contact Name";

    let updateContactName = updateCustomerName.replaceAll(
      "<< Contact Name >>",
      contactName
    );

    let totalPrice = getTotalPrice();

    let updateTotalPrice = updateContactName.replaceAll(
      "<< Invoice Total >>",
      `$ ${totalPrice}`
    );

    let { sizeList, colorsList, fabricsList } = getSizeAndColorsFabricsList();

    let updatedSizeList = updateTotalPrice.replaceAll(
      "<< Sizes List >>",
      sizeList.join(", ")
    );

    let updatedColorList = updatedSizeList.replaceAll(
      "<< Colors List >>",
      colorsList.join(", ")
    );

    let updateBillingAddress = updatedColorList.replaceAll(
      "<< Billing Address >>",
      getAddress(selectedWorkOrder?.billingAddress)
    );

    let updateShippingAddress = updateBillingAddress.replaceAll(
      "<< Shipping Address >>",
      getAddress(selectedWorkOrder?.shippingAddress)
    );

    let updatedFabricsList = updateShippingAddress.replaceAll(
      "<< Fabrics List >>",
      fabricsList.join(", ")
    );

    return updatedFabricsList;
  };
  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getEmailTemplates();
  }, []);

  /*   Table functions
   ********************************************* */

  const table_head = ["Name", "Action"];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td>{item.title}</td>
      <td>
        <button
          onClick={() => {
            setEmailBody(replaceTemplateVariables(item.format));
            setSubject(replaceSubjectVariables(item?.subject));
            showModal(false);
          }}
          className="btn btn-success btn-sm"
        >
          Select
        </button>
      </td>
    </tr>
  );
  return (
    <div>
      {/*   Table
       ********************************************* */}
      <div className="px-2 flex-grow-1 d-flex">
        <div className="horizontal-scrollable d-flex flex-grow-1">
          {emailTemplates ? (
            <Table
              table_head={table_head}
              table_body={table_body}
              table_data={emailTemplates}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className="text-end my-2">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import ArtImageWIthSignedUrl from "./ArtImageWIthSignedUrl";
import Spinner from "components/Spinner";

export default function ArtProductSlider({
  user,
  selectedDesign,
  setSelectedProduct,
  updateSelectedProduct,
}) {
  /*   All States
   ********************************************* */
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign && selectedDesign?.products) {
      setSelectedProduct(
        (prev) => selectedDesign?.products[selectedProductIndex]
      );
    }
  }, [selectedDesign, selectedProductIndex]);

  return (
    <div
      className="bg-white scrollbar-hide custom-border-radius custom-shadow-sm p-2 pt-3 overflow-scroll"
      style={{ width: "13%", height: "77vh" }}
    >
      {loading && <Spinner />}
      {selectedDesign?.products?.map((product, index) => (
        <div
          className="bg-white mb-2 custom-shadow-sm custom-border-radius-sm"
          onClick={async () => {
            if (product?.newAppMsg) {
              loadingOn();
              await updateSelectedProduct({ ...product, newAppMsg: false });
              loadingOff();
            }
            setSelectedProductIndex((prev) => index);
          }}
          key={index}
        >
          <div
            className={`${
              selectedProductIndex === index
                ? "bg-primary bg-opacity-50"
                : "bg-light"
            }  mx-auto flex-center p-2 position-relative`}
            style={{ width: "auto", height: 100 }}
          >
            <div className="btn btn-sm btn-primary position-absolute top-0 start-0">
              {product?.id}
            </div>
            {product?.newAppMsg && (
              <small
                style={{ bottom: 0, right: -5 }}
                className="position-absolute bg-danger text-white p-0 px-1"
              >
                New
              </small>
            )}
            <ArtImageWIthSignedUrl
              imgStyle={{
                width: "100%",
                height: "100%",
                objectFit: "object-fit",
              }}
              path={product?.images[0].img}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userLogin } from "../features/user/userActions";
import { getCompany } from "../features/company/companyActions";
import { setNotification } from "../features/Notification/notificationSlice";

import Toast from "../components/Toast/ToastComponent";
import API from "services/axios";
import authHeader from "services/auth-header";
import Spinner from "components/Spinner";

export default function CustomerLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [formData, setFormData] = useState(false);
  const [companiesList, setCompaniesList] = useState(false);
  const [customersList, setCustomersList] = useState(null);
  const [filteredCustomerList, setFilteredCustomerList] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { loading, userInfo } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);

  useEffect(() => {
    document.querySelectorAll(".needs-validation").forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });

    if (
      userInfo !== null &&
      userInfo?.companyId !== undefined &&
      company?.id === userInfo?.companyId
      // && company?.subscriptionPlan !== null
    ) {
      if (userInfo.roles === "customer") {
        const companyName = companiesList.find(
          (item) => item?.id === formData?.companyId
        );

        navigate(
          `/portal/${companyName?.name
            ?.toLowerCase()
            .replaceAll(" ", "-")}/quotations`
        );
      } else {
        navigate("/dashboard");
      }
    } else if (userInfo !== null && userInfo?.companyId === undefined) {
      navigate("/", {
        state: { stepId: 1 },
      });
    }
    // else if (
    //   userInfo !== null &&
    //   userInfo?.companyId !== undefined &&
    //   company?.subscriptionPlan === null
    // ) {
    //   navigate("/signup", {
    //     state: { stepId: 2 },
    //   });
    // }
  }, [navigate, userInfo, company]);

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!formData?.cid) {
      setIsLoading(false);
      return dispatch(
        setNotification({
          message: "Please select Customer",
          type: "error",
        })
      );
    }

    await dispatch(userLogin({ ...formData, type: "customer" })).then((res) => {
      if (res.type === "auth/login/fulfilled") {
        dispatch(getCompany());
      } else {
        dispatch(
          setNotification({
            message: res.payload,
            type: "error",
          })
        );
      }
    });
    setIsLoading(false);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const getCustomersList = async () => {
    setIsLoading(true);

    try {
      if (!formData?.email) {
        return dispatch(
          setNotification({
            message: "Email is required",
            type: "error",
          })
        );
      }
      // check if formData?.email is valid email by regex
      if (!validateEmail(formData?.email)) {
        return dispatch(
          setNotification({
            message: "Email Format is incorrect",
            type: "error",
          })
        );
      }
      const { data } = await API.post(
        "/portal-customers",
        { email: formData?.email },
        {
          headers: authHeader(),
        }
      );
      if (!data.length) {
        setIsLoading(false);
        return dispatch(
          setNotification({
            message: "No customer found with this email",
            type: "error",
          })
        );
      }
      let companiesList = data?.reduce((acc, item) => {
        let duplicate = false;
        acc.forEach((element) => {
          return element?.id === item.company.id ? (duplicate = true) : false;
        });
        if (duplicate) return acc;

        acc.push({
          id: item.company.id,
          name: item.company.name,
        });
        return acc;
      }, []);
      setCompaniesList((prev) => {
        return companiesList;
      });
      setCustomersList(data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleCompanySelect = (selectedCompany) => {
    const filteredList = customersList?.filter(
      (item) => item?.company.id === parseInt(selectedCompany)
    );
    setFormData((prev) => {
      return { ...prev, cid: parseInt(filteredList[0]?.id) };
    });
    setFilteredCustomerList(filteredList);
    setFormData((prev) => {
      return { ...prev, companyId: parseInt(selectedCompany) };
    });
  };

  return (
    <>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="index.html">
                    <span>
                      <img
                        src={require("../assets/images/logo.png")}
                        alt="logo"
                        height="50"
                      />
                    </span>
                  </a>
                </div>
                {isLoading && <Spinner />}
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center pb-0 fw-bold">
                      Sign In
                    </h4>
                    <p className="text-muted mb-4">
                      Enter your email address and password to access admin
                      panel.
                    </p>
                  </div>

                  <form
                    className="needs-validation"
                    onSubmit={submitForm}
                    noValidate
                  >
                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        value={formData?.email}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return { ...prev, email: e.target.value };
                          });
                        }}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid email.
                      </div>
                    </div>

                    <div className="mb-3">
                      <button
                        onClick={getCustomersList}
                        type="button"
                        disabled={!formData?.email}
                        className="btn btn-success w-100"
                      >
                        Check
                      </button>
                    </div>

                    {companiesList && (
                      <div className="mb-3">
                        <label htmlFor="companyList" className="form-label">
                          Select Company
                        </label>
                        <select
                          required
                          className="form-select"
                          onChange={(e) => {
                            handleCompanySelect(e.target.value);
                          }}
                        >
                          <option
                            value={""}
                            className="bg-secondary text-white"
                            selected
                            disabled
                          >
                            Select Company
                          </option>
                          {companiesList?.map((item, i) => (
                            <option key={item?.id} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {filteredCustomerList && (
                      <>
                        <div className="mb-3">
                          <label htmlFor="customerList" className="form-label">
                            Select Customer
                          </label>

                          <select
                            required
                            className="form-select"
                            // value={formData?.cid}
                            onChange={(e) => {
                              setFormData((prev) => {
                                return {
                                  ...prev,
                                  cid: parseInt(e.target.value),
                                };
                              });
                            }}
                          >
                            <option
                              value={""}
                              className="bg-secondary text-white"
                              disabled
                            >
                              Select Customer
                            </option>
                            {filteredCustomerList?.map((item, i) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.customerName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              className="form-control"
                              type={showPass ? "text" : "password"}
                              placeholder="Enter your password"
                              value={formData?.password}
                              onChange={(e) => {
                                setFormData((prev) => {
                                  return { ...prev, password: e.target.value };
                                });
                              }}
                              required
                            />
                            <div
                              className="input-group-text"
                              data-password="false"
                              onClick={() => setShowPass(!showPass)}
                            >
                              <span className="password-eye"></span>
                            </div>
                          </div>
                          <Link
                            to={"/forget-password"}
                            className="text-muted float-end"
                          >
                            <small>Forgot your password?</small>
                          </Link>
                        </div>
                        <div className="mb-3 mb-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="checkbox-signin"
                              name="remember"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkbox-signin"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        {loading && (
                          <div className="text-center my-2">
                            <Spinner />
                          </div>
                        )}
                        <div className="mb-3 mb-0 text-center">
                          <button
                            disabled={!formData?.email || !formData?.password}
                            className="btn btn-primary"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted ">
                    {/* Don't have an account?{" "} */}
                    <Link to={"/login"} className=" ">
                      <span className="font-weight-bold">
                        Click here to go to login
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
